export const convertJsonToDict = (rootObj: any, obj: any, dict: { [key: string]: string }, prefix: string = '') => {
  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (value !== null && value !== undefined) {
      if (typeof value === 'object' || Array.isArray(value)) {
        const newPrefix = `${prefix}${key}.`

        if (Array.isArray(value) && !checkArrayType(value, 'string') && !checkArrayType(value, 'number')) {
          value.forEach((item, i) => {
            convertJsonToDict(rootObj, item, dict, `${newPrefix}${i}.`);
          })
        } else {
          convertJsonToDict(rootObj, value, dict, newPrefix);
        }
      } else if (isNullOrWhiteSpace(value) === false) {
        dict[prefix + key] = obj[key];
      }
    }
  });
}

export const convertXmlToDict = (element: Element, dict: { [key: string]: string }, prefix: string | null = null) => {
  const children = element.children;
  const localName = element.localName;
  const nodeValue = element.textContent || '';

  if (children.length === 0 && isNullOrWhiteSpace(nodeValue) === false) {
    dict[prefix + localName] = nodeValue;
  }

  if (prefix === null) {
    prefix = '';
  } else {
    prefix += `${localName}.`;
  }

  const attributes = element.attributes;
  for (const attribute of attributes) {
    const attrValue = attribute.value;

    if (isNullOrWhiteSpace(attrValue) === false) {
      dict[prefix + attribute.localName] = attrValue;
    }
  }

  for (const child of children) {
    convertXmlToDict(child, dict, prefix);
  }
}

const checkArrayType = (input: any[], type: string) => {
  try {
    return input.every(item => typeof item === type);
  } catch (e) {
    return false;
  }
}

const isNullOrWhiteSpace = (value: string) => {
  return (/^\s*$/).test(value);
}

export const stringifyDictionary = (dict: { [key: string]: string }) => {
  let result = '';
  Object.keys(dict).forEach((key, i) => {
    result += `${key}: ${dict[key]}`;
    if (i !== Object.keys(dict).length - 1) {
      result += '<br/>';
    }
  });

  return result;
}
