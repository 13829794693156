import { LinkRawFields, LinkElementType, LinkTypeClassifier } from '@core/models/relation-map-types';
import { IdGenerator } from '@core/utils/id-generator';
import { isValidNumber, preciseRound } from '@core/utils/number-helpers';
import { useForm } from '@hooks/useForm';

export type EditLinkForm = {
  type: LinkElementType | null;
  linkWeight: number | null;
  linkType: LinkTypeClassifier | null;
};

const initForm = (
  link: LinkRawFields | null,
): EditLinkForm => {
  const defaultLink = {
    type: null,
    linkWeight: null,
    linkType: null
  };

  if (link) {
    return {
      type: <LinkElementType>(link.type || defaultLink.type),
      linkWeight: link.linkWeight !== null && link.linkWeight !== undefined ? link.linkWeight : defaultLink.linkWeight,
      linkType: link.linkType || defaultLink.linkType
    }
  }

  return defaultLink;
};

export const useLinkEditForm = (
  onSave: (value: LinkRawFields) => void,
  linkData: LinkRawFields | null,
) => {

  const {
    value,
    onChange,
    onValidate,
    validation,
    formTouched,
    setFormTouched,
    setInitialValue,
  } = useForm<EditLinkForm, EditLinkForm>(initForm(linkData), {
    type: (form: EditLinkForm) => form.type !== null,
    linkWeight: (form: EditLinkForm) => form.linkWeight !== null && form.linkWeight >= 0 && form.linkWeight <= 1 && isValidNumber(form.linkWeight),
    linkType: (form: EditLinkForm) => form.type === 'LinkOtherOutputType'
      ? form.linkType !== null && form.linkType.trim().length > 0
      : true,
  });

  const onChangeForm = (key: keyof EditLinkForm, value: any) => {
    onChange(key, value);
  };

  const onSaveForm = async () => {
    const isValidObject = onValidate();

    if (!isValidObject) {
      return;
    }

    const {
      type,
      linkWeight,
      linkType
    } = value;

    if (!type) {
      return;
    }

    const linkFields: LinkRawFields = {
      ...(linkData || { id: IdGenerator.getId(), id1: IdGenerator.getId(), id2: IdGenerator.getId() }),
      type: type,
      linkWeight: linkWeight !== null ? preciseRound(linkWeight, 2) : linkWeight,
      linkType: type === 'LinkOtherOutputType' && linkType ? linkType : undefined,
    };

    onSave(linkFields);
    setFormTouched(false);
  };

  return {
    formValue: value,
    validation,
    formTouched,
    onSaveForm,
    onChangeForm,
    onValidate,
  };
};
