import * as d3 from 'd3';

var advancedPaths = false;

export function Renderer(graph) {
  this.graph = graph;
}

Renderer.prototype.selector = 'g.ak_link';

//создает svg элементы узла
Renderer.prototype.create = function (sel) {
  var graph = this.graph;
  var timeout = null;

  //группа, в которой находятся элементы ребра графа
  var link = sel
    .append('svg:g')
    .attr('class', 'ak_link')
    .on('mouseover.graph', function (d) {
      d3.select(this).classed('hover', true);
    })
    .on('mouseout.graph', function (d) {
      d3.select(this).classed('hover', false);
    })
    .on('click.graph', function (d) {
      clearTimeout(timeout);

      timeout = setTimeout(function () {
        graph.event.linkClick({ d: d, el: this });
      }, 300);
    })
    .on('dblclick.graph', function (d) {
      clearTimeout(timeout);

      graph.event.linkDblClick({ d: d, el: this });
    });

  link.attr('linkId', (d) => d.ID);

  //линия для реакции на мышку
  if (advancedPaths) {
    link
      .append('svg:path')
      .attr('class', 'ak_link_path2')
      .on('click.graph', function (d) {
        graph.event.linkClick({ d: d, el: this });
      });
  }
  //линия ребра
  link
    .append('svg:path')
    .attr('class', 'ak_link_path')
    .attr('linkId', (d) => d.ID);

  link
    .append('svg:path')
    .attr('class', 'ak_link_subpath')
    .attr('linkId', (d) => d.ID);

  //всплывающая подсказка ребра
  link.append('title');

  //this.update(link);
  return link;
};

//устанавливает свойства узла
Renderer.prototype.update = function (sel) {
  var graph = this.graph;
  sel.each(function (d) {
    d.view = this;
  });
  var path = sel
    .select('path.ak_link_path')
    .attr('stroke-width', function (d) {
      return d.width;
    })
    .attr('stroke', function (d) {
      return d.color ? d.color : null;
    })
    .on('click.graph', function (d) {
      graph.event.linkClick({ d: d, el: this });
    });

  sel.select('path.ak_link_subpath').attr('stroke', 'transparent');

  var graph = this.graph;
  path
    .attr('marker-start', function (d) {
      return d.type == graph.LINK_TYPE_BIDIRECTIONAL ? graph.markerFor(d.color, false) : null;
    })
    .attr('marker-end', function (d) {
      return d.type == graph.LINK_TYPE_DIRECTED || d.type == graph.LINK_TYPE_BIDIRECTIONAL
        ? graph.markerFor(d.color, true)
        : null;
    });

  sel.select('title').text(function (d) {
    return d.title;
  });
  this.updateSelection(sel);
  this.position(sel);
};

Renderer.prototype.updateSelection = function (links) {
  links.classed('selected', function (d) {
    return d.selected;
  });
};

//создает маркер с определенным цветом
function drawMarker(parent, color, className) {
  var g = parent.append('svg:g').attr('class', className);
  g.append('svg:path').attr('d', 'M-8,-4L0,0L-8,4z').attr('fill', color).attr('stroke', 'none');
  return g;
}

//позиционирует ребра графа
//links - список ребер для позиционирования (селектор)

Renderer.prototype.position = function (links) {
  //радиус иконки
  var iconRadius = this.graph.baseIconWidth / 2;
  var marix = this.graph.matrix;
  var isIE = this.graph.isIE;
  var scale = this.graph.scaleInv;
  var skeleton = this.graph.isSkeleton();
  var padding = 5 * scale;
  links.each(function (d) {
    var path = this.firstChild;

    const subPath = this.querySelector('path.ak_link_subpath');

    path.setAttribute('stroke-width', d.width * scale);
    subPath.setAttribute('stroke-width', d.width * scale * 10);

    var dx = d.target.xd - d.source.xd;
    var dy = d.target.yd - d.source.yd;
    //дополнительный отступ между иконкой и стрелочками
    var size0 = iconRadius * d.source.scale * (skeleton ? 1 : scale);
    var size1 = iconRadius * d.target.scale * (skeleton ? 1 : scale);

    //петлевые связи
    if (d.source == d.target || (dx == 0 && dy == 0)) {
      if (skeleton) {
        this.style.display = 'none';
        return;
      }
      this.style.display = '';

      var x0 = d.source.xd - padding;
      var y0 = d.source.yd - size0;
      var x1 = d.source.xd + padding;
      var y1 = d.source.yd - size0 - padding * 4;
      drawPath(
        path,
        'M' +
          x0 +
          ',' +
          y0 +
          'C' +
          (x0 - padding * 4) +
          ',' +
          y1 +
          ' ' +
          (x1 + padding * 4) +
          ',' +
          y1 +
          ' ' +
          x1 +
          ',' +
          y0,
      );
      //для IE нужно размещать маркеры вручную
      return;
    }

    var r = 0.0001;
    if (dx != 0 || dy != 0) {
      r = Math.sqrt(dx * dx + dy * dy);
    }
    var margin0 = (size0 * 1.5) / r;
    var margin1 = (size1 * 1.5) / r;
    var x0 = d.source.xd + dx * margin0,
      y0 = d.source.yd + dy * margin0;
    x1 = d.target.xd - dx * margin1;
    y1 = d.target.yd - dy * margin1;
    if (isNaN(x0) || isNaN(x1) || isNaN(y1)) {
      x0 = 0;
    }

    var linkOffset = d.linkOffset;
    var m = marix[d.source.id];
    var m2 = m ? m[d.target.id] : null;
    if (!m2) {
      debugger;
    }
    //для узлов с четным числом ссылок сделать чтобы дуги шли симметрично
    var mlen = m2 ? m2.length : 0; // marix[d.source.id][d.target.id].length;
    if (mlen == 2 || mlen == 4 || mlen == 6) linkOffset += d.target.id > d.source.id ? 0.5 : -0.5;

    if (linkOffset != 0) {
      var OFFSET = 2; //макс расстояние между соседними ребрами в пикс
      var c = (OFFSET * linkOffset) / r;
      //сдвигаем ребро вбок на несколько пикселов
      var ax = -dy * c,
        ay = dx * c,
        px0 = x0 + ax,
        py0 = y0 + ay,
        qx = (x1 + x0) / 2 + ax * 5,
        qy = (y1 + y0) / 2 + ay * 5,
        px1 = x1 + ax,
        py1 = y1 + ay;
      drawPath(path, 'M' + px0 + ',' + py0 + 'Q' + qx + ',' + qy + ' ' + px1 + ',' + py1);
      drawPath(subPath, 'M' + px0 + ',' + py0 + 'Q' + qx + ',' + qy + ' ' + px1 + ',' + py1);
      //для IE нужно размещать маркеры вручную
      if (isIE) {
        // var $this = d3.select(this),
        //   marker;
        // if (d.type == d3.layout.ak.LINK_TYPE_BIDIRECTIONAL) {
        //   $this
        //     .select('g.ak_marker_start')
        //     .attr(
        //       'transform',
        //       'translate(' +
        //         px0 +
        //         ',' +
        //         py0 +
        //         ') rotate(' +
        //         (Math.atan2(py0 - qy, px0 - qx) * 180) / Math.PI +
        //         ') scale(' +
        //         scale +
        //         ')',
        //     );
        // }
        // if (d.type == d3.layout.ak.LINK_TYPE_DIRECTED || d.type == d3.layout.ak.LINK_TYPE_BIDIRECTIONAL) {
        //   $this
        //     .select('g.ak_marker_end')
        //     .attr(
        //       'transform',
        //       'translate(' +
        //         px1 +
        //         ',' +
        //         py1 +
        //         ') rotate(' +
        //         (Math.atan2(py1 - qy, px1 - qx) * 180) / Math.PI +
        //         ') scale(' +
        //         scale +
        //         ')',
        //     );
        // }
      }
    } else {
      drawPath(path, 'M' + x0 + ',' + y0 + 'L' + x1 + ',' + y1);
      drawPath(subPath, 'M' + x0 + ',' + y0 + 'L' + x1 + ',' + y1);
    }
  });
};

function drawPath(path, d) {
  path.setAttribute('d', d);
  if (advancedPaths) {
    var p2 = path.nextSibling;
    p2.setAttribute('d', d);
  }
}
