import { FC, useEffect, useMemo, useRef, useState } from 'react';
import AppSplitPane from '@components/AppSplitPane/AppSplitPane';
import AppTooltip from '@components/AppTooltip';
import { useSplitPane } from '@components/AppSplitPane/useSplitPane';
import SimpleTabs from '@components/SimpleTabs';
import SmartTable from '@components/SmartTable';
import { RelationMapNode } from '@core/classes/relation-map-node';
import { RelationMapLink } from '@core/classes/relation-map-link';
import { RelationMapDto, NodeRawFields, LinkRawFields } from '@core/models/relation-map-types';
import { IRelationMapVisualization } from '@core/models/relation-map-visualization';
import { ZoomNetwork } from '@core/types/zoom-network';
import { XmlConverter } from '@core/utils/xml-converter';
import { Box, Container, IconButton, Paper, createStyles, makeStyles, Theme } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import CreateNodeDialog from './components/CreateNodeDialog';
import CreateLinkDialog from './components/CreateLinkDialog';
import FileActions from './components/FileActions';
import Graph from './components/Graph';
import { ContextRelationMapAction } from './components/Graph/list';
import ZoomMapSettings from './components/ZoomMapSettings';
import { edgesTableColDefs } from './tables/Edges/col-defs';
import { EDGES_TABLE } from './tables/Edges/edges-table';
import { nodesTableColDefs } from './tables/Nodes/col-defs';
import { NODES_TABLE } from './tables/Nodes/nodes-table';
import { DocumentEventsGraph, DocumentEventsGraphTypes } from './events';
import VisualizationOptions from './visualization';
import { ObjectGraph } from '@vendor/xf/object-graph';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    panel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '8px 0',
    },
    content: {
      height: '100%',
      margin: '8px 0',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    dialog: {
      minWidth: '900px',
      padding: '8px 15px',
      fontSize: '16px',
    },
    table: {
      height: '94%',
      padding: '0 10px 8px 10px',
    },
    btnGroup: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 10px'
    },
    fileControls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto'
    },
    navControls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      margin: '0 10px'
    },
    relationMapGraph: {
      display: 'flex',
      height: '100%'
    }
  }),
);

export enum ModalViews {
  CREATE_NODE = 'CREATE_NODE',
  EDIT_NODE = 'EDIT_NODE',
  CREATE_LINK = 'CREATE_LINK',
  EDIT_LINK = 'EDIT_LINK'
}

const RelationMap: FC = () => {

  const classes = useStyles();

  const [file, setFile] = useState<File | null>(null);

  const fileRef = useRef<File | null>(null);

  const [data, setData] = useState<RelationMapDto | null>(null);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [graph, setGraph] = useState<ObjectGraph | undefined>(undefined);

  const [currentNodes, setCurrentNodes] = useState<RelationMapNode[]>([]);

  const currentNodesRef = useRef<RelationMapNode[]>([]);

  const [hiddenNodesIds, setHiddenNodesIds] = useState<string[]>([]);

  const hiddenNodesIdsRef = useRef<string[]>([]);

  const [selectedNodesIds, setSelectedNodesIds] = useState<string[]>([]);

  const selectedNodesIdsRef = useRef<string[]>([]);

  const editNodeRef = useRef<NodeRawFields | null>(null);

  const [currentEdges, setCurrentEdges] = useState<RelationMapLink[]>([]);

  const currentEdgesRef = useRef<RelationMapLink[]>([]);

  const [hiddenEdgesIds, setHiddenEdgesIds] = useState<string[]>([]);

  const hiddenEdgesIdsRef = useRef<string[]>([]);

  const [selectedEdgesIds, setSelectedEdgesIds] = useState<string[]>([]);

  const selectedEdgesIdsRef = useRef<string[]>([]);

  const editEdgeRef = useRef<{ id: string, fields: LinkRawFields } | null>(null);

  const [preventInit, setPreventInit] = useState<boolean>(false);

  const keepPositionsRef = useRef<boolean>(false);

  const [visualizationOptions, setVisualizationOptions] = useState<IRelationMapVisualization>(VisualizationOptions);

  const [dialogOpen, setDialogOpen] = useState<Nullable<ModalViews>>(null);

  const splitPaneRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.title = 'СПО КВ';
  }, []);

  useEffect(() => {
    fileRef.current = file;
  }, [file]);

  useEffect(() => {
    loadMapData();
  }, [data]);

  useEffect(() => {
    document.addEventListener(DocumentEventsGraphTypes.CLICK_NODE, clickNodeHandle);
    document.addEventListener(DocumentEventsGraphTypes.CLICK_LINK, clickEdgeHandle);
    document.addEventListener(DocumentEventsGraphTypes.HIDDEN_LINKS_RELATED, hiddenEdgeChangeHandle);
    document.addEventListener(DocumentEventsGraphTypes.RESPONSE_POSITIONS, positionsResponseHandle);
  }, []);

  useEffect(() => {
    currentNodesRef.current = currentNodes;
  }, [currentNodes]);

  useEffect(() => {
    hiddenNodesIdsRef.current = hiddenNodesIds;
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.HIDDEN_NODES, {
      nodes: hiddenNodesIds,
    });
  }, [hiddenNodesIds]);

  useEffect(() => {
    selectedNodesIdsRef.current = selectedNodesIds;
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.SELECTED_NODES, selectedNodesIds);
  }, [selectedNodesIds]);

  useEffect(() => {
    currentEdgesRef.current = currentEdges;
  }, [currentEdges]);

  useEffect(() => {
    hiddenEdgesIdsRef.current = hiddenEdgesIds;
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.HIDDEN_LINKS, {
      links: hiddenEdgesIds,
    });
  }, [hiddenEdgesIds]);

  useEffect(() => {
    selectedEdgesIdsRef.current = selectedEdgesIds;
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.SELECTED_LINKS, selectedEdgesIds);
  }, [selectedEdgesIds]);

  const onFileLoad = (file: File) => {
    setDataLoaded(false);

    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => {
      if (reader.result && typeof reader.result == 'string') {
        setFile(file);

        if (file.type === 'application/json') {
          const map = JSON.parse(reader.result) as RelationMapDto;

          const nodes: RelationMapNode[] = [];
          const links: RelationMapLink[] = [];
          keepPositionsRef.current = true;

          map.nodes.forEach(jsonNode => {
            const node = new RelationMapNode(jsonNode.fields, jsonNode.position);
            if (node) {
              nodes.push(node);
            }
          });

          map.links.forEach(jsonLink => {
            const link = new RelationMapLink(jsonLink.fields);
            if (link) {
              links.push(link);
            }
          });

          setData({ nodes, links });
        }

        if (file.type === 'text/xml') {
          const xml = new XmlConverter(reader.result);
          const map = xml.parse();

          keepPositionsRef.current = false;
          setData(map);
        }
      }
    };
  }

  const loadMapData = () => {
    try {
      if (data) {
        const { nodes, links }: RelationMapDto = data;

        setCurrentNodes(nodes);
        setCurrentEdges(links);
      } else {
        setCurrentNodes([]);
        setCurrentEdges([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setPreventInit(false);
      setDataLoaded(true);
    }
  };

  const onFileSave = () => {
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.SAVE_FILE, null);
  };

  const saveToJson = (detailedNodes: any[]) => {
    const data = prepareModifiedData(detailedNodes);
    saveFile(data);
  };

  const prepareModifiedData = (detailedNodes: any[]) => {
    const objectData = {
      nodes: currentNodesRef.current.filter(node => !hiddenNodesIdsRef.current.includes(node.id)).map((node) => {
        const dn = detailedNodes.find(x => x.id === node.id);

        return {
          fields: node.fields,
          position: dn ? { x: dn.x, y: dn.y, xd: dn.xd, yd: dn.yd } : null
        }
      }),
      links: currentEdgesRef.current.filter(edge => !hiddenEdgesIdsRef.current.includes(edge.id)).map((edge) => {
        return {
          fields: edge.fields
        }
      }),
    };

    return JSON.stringify(objectData, null, 2);
  }

  const saveFile = (jsonData: string) => {
    const textFileAsBlob = new Blob([jsonData], { type: 'application/json' });
    const downloadLink = document.createElement('a');
    downloadLink.download = fileRef?.current?.type === 'application/json'
      ? fileRef?.current?.name
      : `${fileRef?.current?.name}.json`;
    downloadLink.innerHTML = 'Download File';
    if (window.webkitURL !== null) {
      downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
      downloadLink.click();
    }
  }

  const handleSetGraph = (graphObj: ObjectGraph | undefined) => setGraph(graphObj);

  const clickNodeHandle = (event: any) => {
    const data = event.detail.data;

    if (data.length === 2) {
      const id = data[0];
      const multiselect = data[1];

      const hasId: boolean = selectedNodesIdsRef.current.some((nodeId) => nodeId === id);

      if (hasId === true) {
        if (multiselect) {
          const filtered = selectedNodesIdsRef.current.filter((nodeId) => nodeId !== id);
          setSelectedNodesIds([...filtered]);
        } else {
          setSelectedNodesIds([id]);
        }

        return;
      }

      const node = currentNodesRef.current.find((node) => node.id === id);

      if (node === undefined) {
        return;
      }

      if (multiselect) {
        setSelectedNodesIds([...selectedNodesIdsRef.current, id]);
      } else {
        setSelectedNodesIds([id]);
      }
    }
  };

  const clickEdgeHandle = (event: any) => {
    const data = event.detail.data;

    if (data.length === 1) {
      const id = data[0];

      const hasId: boolean = selectedEdgesIdsRef.current.some((linkId) => linkId === id);

      if (hasId === true) {
        setSelectedEdgesIds([]);
      } else if (currentEdgesRef.current.find((link) => link.id === id)) {
        setSelectedEdgesIds([id]);
      };
    }
  };

  const switchNodeVisibility = (id: string, hide: boolean) => {
    const hasId: boolean = hiddenNodesIdsRef.current.some((node) => node === id);

    if (hasId === true && hide || hasId === false && !hide) {
      return;
    }

    const node = currentNodesRef.current.find((node) => node.id === id);

    if (node === undefined) {
      return;
    }

    if (hide) {
      setHiddenNodesIds([...hiddenNodesIdsRef.current, id]);
    } else {
      const filtered = hiddenNodesIdsRef.current.filter((node) => node !== id);
      setHiddenNodesIds([...filtered]);
    }
  };

  const onClickNodeCell = (colId: string, data: RelationMapNode) => {
    if (colId === 'checkbox') {
      switchNodeVisibility(data.id, data.hidden);
    }
  };

  const switchEdgeVisibility = (id: string, hide: boolean) => {
    const hasId: boolean = hiddenEdgesIdsRef.current.some((edge) => edge === id);

    if (hasId === true && hide || hasId === false && !hide) {
      return;
    }

    const edge = currentEdgesRef.current.find((edge) => edge.id === id);

    if (edge === undefined) {
      return;
    }

    if (hide) {
      setHiddenEdgesIds([...hiddenEdgesIdsRef.current, id]);
    } else {
      const filtered = hiddenEdgesIdsRef.current.filter((edge) => edge !== id);
      setHiddenEdgesIds([...filtered]);
    }
  };

  const onClickEdgeCell = (colId: string, data: RelationMapLink) => {
    if (colId === 'checkbox') {
      switchEdgeVisibility(data.id, data.hidden);
    }
  };

  const hiddenEdgeChangeHandle = (event: any) => {
    const { links: ids } = event.detail.data;

    if (!ids) {
      return;
    }
    
    setPreventInit(true);
    setHiddenEdgesIds(ids);
    setCurrentEdges(currentEdgesRef.current.map((edge) => {
      return {
        ...edge,
        hidden: ids.includes(edge.id)
      }
    }));
  };

  const selectedNodesTableRows = useMemo(() => {
    return currentNodes
      .filter((node) => {
        return selectedNodesIds.includes(node.id) === true;
      })
      .map((node) => node.id);
  }, [currentNodes, selectedNodesIds]);

  const onSelectNodesTableRows = (rows: RelationMapNode[]) => {
    const ids = [...rows.map((row) => row['id'])];

    if (ids.length === selectedNodesIds.length) {
      return;
    }

    setSelectedNodesIds(ids);
  };

  const selectedEdgesTableRows = useMemo(() => {
    return currentEdges
      .filter((edge) => {
        return selectedEdgesIds.includes(edge.id) === true;
      })
      .map((edge) => edge.id);
  }, [currentEdges, selectedEdgesIds]);

  const onSelectEdgesTableRows = (rows: RelationMapLink[]) => {
    const ids = [...rows.map((row) => row['id'])];

    setSelectedEdgesIds(ids);
  };

  const changeZoom = (value: ZoomNetwork) => {
    const events = new DocumentEventsGraph();

    events.triggerEvent(DocumentEventsGraphTypes.ZOOM_CHANGED, {
      value,
    });
  };

  const handleContext = (action: ContextRelationMapAction, nodeId: string | null, edgeId: string | null) => {
    if (nodeId !== null) {
      if (action === ContextRelationMapAction.EDIT_NODE) {
        const node = currentNodesRef.current.find((item) => item.id === nodeId);

        if (node) {
          editNodeRef.current = { ...node.fields };
        }

        openDialog(ModalViews.EDIT_NODE);
      }
    }

    if (edgeId !== null) {
      if (action === ContextRelationMapAction.EDIT_LINK) {
        const edge = currentEdgesRef.current.find((item) => item.id === edgeId);

        if (edge) {
          editEdgeRef.current = { id: edgeId, fields: { ...edge.fields } };
        }

        openDialog(ModalViews.EDIT_LINK);
      }
    }
  };

  const openDialog = (type: ModalViews) => setDialogOpen(type);

  const hideDialog = () => setDialogOpen(null);

  const createNode = () => {
    editNodeRef.current = null;
    openDialog(ModalViews.CREATE_NODE);
  };

  const updateNode = (value: NodeRawFields) => {
    setPreventInit(false);
    keepPositionsRef.current = true;
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.EDIT_NODE, value);
  };

  const updateNodeData = (value: NodeRawFields, detailedNodes: any[]) => {
    const dn: any = detailedNodes.find(x => x.id === value.id);
    const node = new RelationMapNode(value, dn ? { x: dn.x, y: dn.y, xd: dn.xd, yd: dn.yd } : null);
    const isExist = currentNodesRef.current.find(item => item.id === node.id);

    let nodes: RelationMapNode[] = [];
    if (isExist) {
      nodes = addPositionsToNodes(detailedNodes, node);
    } else {
      nodes = [...addPositionsToNodes(detailedNodes, null), node];
    }

    setCurrentNodes(nodes);
    hideDialog();
  };

  const addPositionsToNodes = (detailedNodes: any[], updatedNode: RelationMapNode | null) => {
    const nodes = currentNodesRef.current.map(item => {
      const dn: any = detailedNodes.find(x => x.id === item.id);
      const result = updatedNode && item.id === updatedNode.id
        ? updatedNode
        : {
          ...item,
          position: dn ? { x: dn.x, y: dn.y, xd: dn.xd, yd: dn.yd } : null
        };

      return result;
    });

    return nodes;
  }

  const createEdge = () => {
    editEdgeRef.current = {
      id: '',
      fields: {
        id1: selectedNodesIds[0],
        id2: selectedNodesIds[1],
        type: null
      }
    };

    openDialog(ModalViews.CREATE_LINK);
  };

  const updateEdge = (value: LinkRawFields) => {
    setPreventInit(false);
    keepPositionsRef.current = true;
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.EDIT_LINK, value);
  };

  const updateEdgeData = (value: LinkRawFields, detailedNodes: any[]) => {
    const nodes = addPositionsToNodes(detailedNodes, null);

    const edge = new RelationMapLink(value);
    const isExist = currentEdgesRef.current.find(item => item.id === editEdgeRef?.current?.id);

    let edges: RelationMapLink[] = [];
    if (isExist) {
      edges = currentEdgesRef.current.map(item => item.id === editEdgeRef?.current?.id ? edge : item);
    } else {
      edges = [...currentEdgesRef.current, edge];
      setSelectedNodesIds([]);
    }

    setCurrentNodes(nodes);
    setCurrentEdges(edges);
    hideDialog();
  };

  const positionsResponseHandle = (event: any) => {
    const { nodes, requestData } = event.detail.data;

    switch (requestData.key) {
      case DocumentEventsGraphTypes.SAVE_FILE:
        saveToJson(nodes);
        break;

      case DocumentEventsGraphTypes.EDIT_NODE:
        updateNodeData(requestData.value, nodes);
        break;

      case DocumentEventsGraphTypes.EDIT_LINK:
        updateEdgeData(requestData.value, nodes);
        break;
    }
  };

  const splitPane = useSplitPane({ minSize: 30 });

  return (
    <>
      <Box className={classes.root}>
        <Container className={classes.root}>
          <Paper elevation={1} className={classes.panel}>
            <div className={classes.fileControls}>
              <FileActions onLoad={onFileLoad} onSave={onFileSave} />
            </div>
            <div className={classes.btnGroup}>
              <AppTooltip title='Создать узел'>
                <div>
                  <IconButton
                    disabled={data === null}
                    size='small'
                    onClick={createNode}
                  >
                    <AddCircleOutlineIcon fontSize='small' />
                  </IconButton>
                </div>
              </AppTooltip>
              <AppTooltip title='Создать связь'>
                <div>
                  <IconButton
                    disabled={data === null || selectedNodesIds.length !== 2}
                    size='small'
                    onClick={createEdge}
                  >
                    <PlaylistAddOutlinedIcon fontSize='small' />
                  </IconButton>
                </div>
              </AppTooltip>
            </div>
            <div className={classes.navControls}>
              <ZoomMapSettings
                disabled={data === null}
                onChange={changeZoom}
              />
            </div>
          </Paper>

          <Paper elevation={1} className={classes.content}>
            <div style={{ height: '100%' }} ref={splitPaneRef}>
              <AppSplitPane
                allowTogglePanel
                position={splitPane.position}
                maxSize={splitPane.maxSize}
                size={splitPane.sizePane}
                onChangeSize={splitPane.onChangeSize}
                onInit={splitPane.onInit}
                onTogglePanel={splitPane.togglePanel}
              >

                <SimpleTabs labels={['Вершины', 'Связи']}>
                  <div className={classes.table}>
                    <SmartTable
                      tableConfig={NODES_TABLE}
                      columnsDef={nodesTableColDefs}
                      rowsData={currentNodes}
                      rowMultiSelectWithClick={true}
                      selectedRowIds={selectedNodesTableRows}
                      onSelectRows={onSelectNodesTableRows}
                      onClickCell={onClickNodeCell}
                    />
                  </div>
                  <div className={classes.table}>
                    <SmartTable
                      tableConfig={EDGES_TABLE}
                      columnsDef={edgesTableColDefs}
                      rowsData={currentEdges}
                      rowMultiSelectWithClick={false}
                      rowSelection={'single'}
                      selectedRowIds={selectedEdgesTableRows}
                      onSelectRows={onSelectEdgesTableRows}
                      onClickCell={onClickEdgeCell}
                    />
                  </div>
                </SimpleTabs>

                <div id='relationMapGraph' className={classes.relationMapGraph}>
                  {dataLoaded === true && (
                    <Graph
                      graph={graph}
                      nodes={currentNodes}
                      links={currentEdges}
                      hiddenNodesIds={hiddenNodesIds}
                      selectedNodesIds={selectedNodesIds}
                      preventInit={preventInit}
                      keepPositions={keepPositionsRef.current}
                      options={visualizationOptions}
                      onAction={handleContext}
                      onSetGraph={handleSetGraph}
                    />
                  )}
                </div>
              </AppSplitPane>
            </div>
          </Paper>
        </Container>
      </Box>

      {(dialogOpen === 'CREATE_NODE' || dialogOpen === 'EDIT_NODE') && (
        <CreateNodeDialog
          fields={editNodeRef.current}
          onSave={updateNode}
          onCancel={hideDialog}
        />
      )}

      {(dialogOpen === 'CREATE_LINK' || dialogOpen === 'EDIT_LINK') && (
        <CreateLinkDialog
          fields={editEdgeRef.current}
          onSave={updateEdge}
          onCancel={hideDialog}
        />
      )}

    </>
  )};

export default RelationMap;
