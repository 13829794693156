import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface ISimpleTabsProps {
  labels: string[];
  children?: React.ReactNodeArray;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  tab: {
    height: '100%',
  }
}));


const TabPanel = (props: ITabPanelProps) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tab}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SimpleTabs = (props: ISimpleTabsProps) => {
  const classes = useStyles();
  const { labels, children } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        aria-label='simple tabs'
      >
        <Tab label={labels[0]} {...a11yProps(0)} />
        <Tab label={labels[1]} {...a11yProps(1)} />
      </Tabs>
      {children && (
        <>
          <TabPanel value={value} index={0}>
            {children[0]}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {children[1]}
          </TabPanel>
        </>
      )}
    </div>
  );
}

export default SimpleTabs;
