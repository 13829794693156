import React from 'react';
import { useResize } from '@hooks/useResize';
import { EPaneStyle } from '@core/enums/pane-styles';

export const useSplitPane = (params: { minSize: number }, reverseExpand = false) => {

  const { width } = useResize();

  const [position, setPosition] = React.useState<EPaneStyle>(EPaneStyle.VERT);

  const [sizePane, setSizePane] = React.useState<number>(0);

  const [maxWidth, setMaxWidth] = React.useState<number>(0);

  const [maxHeight, setMaxHeight] = React.useState<number>(0);

  const refContainer = React.useRef<Nullable<HTMLDivElement>>(null);

  const refInitComplete = React.useRef<boolean>(false);

  const onInit = (ref: HTMLDivElement) => {
    refContainer.current = ref;
    setMaxWidth(ref.clientWidth);
    setMaxHeight(ref.clientHeight);
    setSizePane(ref.clientWidth * params.minSize / 100);
    refInitComplete.current = true;
  };

  const forceReInit = () => {
    if (refContainer.current && refInitComplete.current) {
      setMaxWidth(refContainer.current.clientWidth);
      setMaxHeight(refContainer.current.clientHeight);
    }
  };

  React.useEffect(() => {
    if (refContainer.current && refInitComplete.current) {
      setMaxWidth(refContainer.current.clientWidth);
      setMaxHeight(refContainer.current.clientHeight);
    }
  }, [width]);

  const setPercentSize = (percent: number) => {
    if (position === 'vertical') {
      setSizePane(maxWidth * percent / 100);
    } else {
      setSizePane(maxHeight * percent / 100);
    }
  };

  const toggleContentPosition = () => {
    setPosition(position === EPaneStyle.VERT ? EPaneStyle.HOR : EPaneStyle.VERT);
  };

  const maxSize = position === 'vertical' ? maxWidth : maxHeight;

  const togglePanel = () => {
    if (sizePane === maxSize || sizePane > 0 && sizePane < 10) {
      setPercentSize(position === 'vertical' ? params.minSize : 50);
    } else {
      setPercentSize(reverseExpand ? 100 : 0.2);
    }
  };

  const splitterClosed = React.useMemo(() => sizePane < 10, [sizePane]);

  const splitterRight = React.useMemo(() => sizePane === maxSize, [sizePane, maxSize]);

  const panelPercentSize = React.useMemo(() => sizePane * 100 / maxSize, [maxSize, sizePane]);

  return {
    maxSize,
    maxWidth,
    maxHeight,
    sizePane,
    position,
    splitterClosed,
    splitterRight,
    panelPercentSize,
    onChangeSize: setSizePane,
    onInit,
    forceReInit,
    setPercentSize,
    togglePanel,
    toggleContentPosition,
  };
};
