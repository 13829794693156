import { IRelationMapVisualization } from '@core/models/relation-map-visualization';

const VisualizationOptions : IRelationMapVisualization = {
  bgColor: '#F8F8F8',
  fontFamily: 'Arial',
  fontSize: 16,
  fontBold: false,
  maxNameSize: 40,
  selectedNodeColor: '#99A529',
  selectedLinkColor: '#C7F43C',
  zoom: 100
}

export default VisualizationOptions;
