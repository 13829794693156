import React from 'react';
import { NodeRawFields } from '@core/models/relation-map-types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AppButton from '@components/AppButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import NodeProperties from './components/NodeProperties';
import { useNodeEditForm } from './hooks/useNodeEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: '12px 10px 10px 10px',
    },
    content: {
      padding: '0 8px',
      width: '800px',
    }
  }),
);

export interface IEditNodeDialogProps {
  fields: NodeRawFields | null;
  onSave: (value: NodeRawFields) => void;
  onCancel: () => void;
}

const CreateNodeDialog = (props: IEditNodeDialogProps) => {

  const { fields, onSave, onCancel } = props;

  const classes = useStyles();

  const isCreate = fields === null;

  const {
    formValue,
    validation,
    formTouched,
    onChangeForm,
    onSaveForm,
  } = useNodeEditForm(onSave, fields);

  const save = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onSaveForm();
  }

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="lg">
      <form onSubmit={save}>
        <DialogTitle className={classes.title}>{isCreate ? 'Создание узла' : 'Редактирование узла'}</DialogTitle>
        <DialogContent className={classes.content}>
          <NodeProperties
            isCreate={isCreate}
            fields={fields}
            formValue={formValue}
            onChangeForm={onChangeForm}
            validation={validation}  
          />
        </DialogContent>
        <DialogActions>
          <Zoom in={isCreate ? true : formTouched}>
            <div>
              <AppButton buttonType="primary" htmlType='submit'>
                Сохранить
              </AppButton>
            </div>
          </Zoom>
          <AppButton buttonType="secondary" onClick={onCancel}>
            Отмена
          </AppButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNodeDialog;
