import { ColDef } from 'ag-grid-community';
import { ISmartTableDto } from '@core/models/smart-table.interface';

export class ColumnsDefSettings {
  private _defs: ColDef[] = [];

  private _tableConfig: ISmartTableDto[] = [];

  constructor(colSDef?: ColDef[], tableConfig?: ISmartTableDto[]) {
    if (colSDef) {
      this.colsDef = colSDef;
    }
    if (tableConfig) {
      this.tableConfig = tableConfig;
    }
  }

  set colsDef(value: ColDef[]) {
    this._defs = value;
  }

  get colsDef(): ColDef[] {
    return this._defs;
  }

  set tableConfig(value: ISmartTableDto[]) {
    this._tableConfig = value;
  }

  get tableConfig(): ISmartTableDto[] {
    return this._tableConfig;
  }

  get hiddenColumnsIds(): string[] {
    return this._tableConfig.filter((col) => col.hide === true).map((col) => col.colId);
  }

  public colsDefFromSettings(): ColDef[] {
    const res: ColDef[] = [];

    this._defs.forEach((def: ColDef) => {
      const setting = this._tableConfig.find((conf) => conf.colId === def.colId);

      if (setting === undefined) {
        return;
      }

      if (setting.width !== undefined) {
        def.width = setting.width;
      }

      def.hide = Boolean(setting.hide);

      if (def.headerName) {
        let width = def.headerName.length * 7 + 50;

        if (def.sortable) {
          width += 25;
        }

        if (def.filter) {
          width += 25;
        }

        def.minWidth = width;
      }

      res[setting.index] = def;
    });

    return res;
  }

  public columnMove(colId: string, toIndex: number): void {
    const movedCol = this._tableConfig.find((col) => col.index === toIndex);
    const moveCol = this._tableConfig.find((col) => col.colId === colId);

    if (moveCol !== undefined && movedCol !== undefined) {
      movedCol.index = moveCol.index;
      moveCol.index = toIndex || 0;
    }
  }

  public columnResize(colId: string, width: number): void {
    const colSettings = this._tableConfig.find((col) => col.colId === colId);

    const colDef = this._defs.find((col) => col.colId === colId);

    if (colDef !== undefined && colDef.lockPosition === true) {
      return;
    }

    if (colSettings !== undefined) {
      colSettings.width = width;
    }
  }

  public toggleHideColumn(colId: string, hide: boolean): void {
    const col = this._tableConfig.find((col) => col.colId === colId);

    if (col !== undefined) {
      col.hide = hide;
    }
  }
}
