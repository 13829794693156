import { ICommonList } from '@core/interfaces/common-list.interface';
import { IdGenerator } from '@core/utils/id-generator';

export enum ContextRelationMapAction {
  EDIT_NODE = 'editNode',
  EDIT_LINK = 'editLink',
}

export const contextNodeItemsMap: ICommonList[] = [
  {
    id: IdGenerator.getId(),
    key: ContextRelationMapAction.EDIT_NODE,
    value: 'Редактировать узел',
  },
];

export const contextEdgeItemsMap: ICommonList[] = [
  {
    id: IdGenerator.getId(),
    key: ContextRelationMapAction.EDIT_LINK,
    value: 'Редактировать связь',
  },
];
