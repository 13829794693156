import React from 'react';

export const useResize = (ref?: HTMLElement) => {
  const [width, setWidth] = React.useState<number>(0);

  const [height, setHeight] = React.useState<number>(0);

  const [element, setElement] = React.useState<HTMLElement | null>(null);

  const refElement = React.useRef<HTMLElement | null>(null);

  const resize = () => {
    let width;
    let height;

    if (refElement.current) {
      width = refElement.current.clientWidth;
      height = refElement.current.clientHeight;
    } else {
      width = window.innerWidth;
      height = window.innerHeight;
    }

    setWidth(width);
    setHeight(height);
  };

  React.useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);

  React.useLayoutEffect(() => {
    if (element) {
      refElement.current = element;
    }
    resize();
  }, [element])

  const forceSetWidth = (value: number) => setWidth(value);

  return {
    width,
    height,
    setElement,
    forceResize: resize,
    forceSetWidth,
  };
};
