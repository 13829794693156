import React from 'react';
import { createStyles, Switch, Theme } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: '0!important',
      marginLeft: '0!important',
    },
  }),
);

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  htmlId?: string;
  label?: string;
}

const AppSwitch: React.FC<Props> = ({value, onChange, htmlId, label}) => {

  const classes = useStyles();

  const onSwitch = (_: any, val: boolean) => onChange(val);

  return (
    <FormControlLabel
      id={htmlId}
      className={classes.root}
      label={label || ''}
      control={
        <Switch
          checked={value}
          color='primary'
          onChange={onSwitch}
        />
      }
    />
  )
};

export default React.memo(AppSwitch);
