import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Box } from '@material-ui/core';
import AppTooltip from '@components/AppTooltip';

const TextCell: React.FC<ICellRendererParams> = ({colDef, value, data}: ICellRendererParams) => {

  const cellValue = React.useMemo(() => {
    if (colDef.filterParams && colDef.filterParams['formatter']) {
      return colDef.filterParams['formatter'](data);
    }

    return value;
  }, [value, data]);

  const tooltip = React.useMemo(() => {
    return colDef.filterParams && colDef.filterParams['tooltipFormatter'] ? colDef.filterParams['tooltipFormatter'](data) : '';
  }, [colDef, data]);

  return (
    <AppTooltip title={tooltip}>
      <Box component="span">{cellValue}</Box>
    </AppTooltip>
  )
};

export default React.memo(TextCell);
