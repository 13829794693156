import { NodeRawFields, NodePosition } from '@core/models/relation-map-types';
import { convertJsonToDict, stringifyDictionary } from '@core/utils/convert-helpers';

export class RelationMapNode {
  public fields;
  public id = '';
  public type = 0;
  public name = '';
  public data = '';
  public hidden = false;
  public position;

  constructor(fields: NodeRawFields, position: Nullable<NodePosition>) {
    this.fields = fields;
    this.id = fields.id;

    if (fields.type === 'NodePersonType') {
      this.type = 1;
      const fio = fields.fio ? (fields.fio.find(item => item.current === true) || fields.fio[0]) : null;
      if (fio) {
        this.name = `${fio.lastName} ${fio.firstName} ${fio.middleName}`;
      }
    }

    if (fields.type === 'NodeOrganizationType') {
      this.type = 2;
      const name = fields.name;
      if (name) {
        this.name = name;
      }
    }

    this.fillData();
    this.position = position || null;
  }

  fillData = () => {
    const values: { [key: string]: string } = {};
    convertJsonToDict(this.fields, this.fields, values);
    this.data = stringifyDictionary(values);
  }
}