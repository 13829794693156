import React, { SyntheticEvent } from 'react';
import { Button, createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IAppButtonProps {
  children: string;
  htmlId?: string;
  htmlType?: string;
  disabled?: boolean;
  styles?:React.CSSProperties;
  buttonType: 'primary' | 'secondary';
  onClick?: (event: SyntheticEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    secondary: {
      backgroundColor: '#fff!important',
      color: theme.palette.primary.main,
    },
  }),
);

const AppButton = (props: IAppButtonProps) => {
  const classes = useStyles();

  const onClick = (event: SyntheticEvent) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Button
      id={props.htmlId}
      style={props.styles}
      disabled={props.disabled}
      variant={props.buttonType === 'primary' ? 'contained' : 'outlined'}
      className={props.buttonType === 'primary' ? classes.primary : classes.secondary}
      type={props.htmlType ? 'submit' : 'button'}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
};

export default AppButton;
