import React from 'react';
import SplitPane from 'react-split-pane';
import { Box, createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AppTooltip from '@components/AppTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
    },
    pane: {
      '& .Pane1': {
        ['@media print']: {
          display: 'none',
        },
      },
    },
    btn: {
      position: 'absolute',
      top: '50%',
      left: 0,
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.dark,
      cursor: 'pointer',
      overflow: 'hidden',
      '& svg': {
        fontSize: '50px',
      },
      ['@media print']: {
        display: 'none',
      },
    },
  }),
);

type Position = 'vertical' | 'horizontal';

type Props = {
  position?: Position;
  maxSize: number;
  size: number;
  allowResize?: boolean;
  allowTogglePanel?: boolean;
  onChangeSize: (size: number) => void;
  onInit: (ref: HTMLDivElement) => void;
  onTogglePanel?: () => void;
  children: React.ReactNodeArray;
  reverseExpand?: boolean;
  pane1InvisibleForPrint?: boolean;
}

const AppSplitPane: React.FC<Props> = (props) => {

  const classes = useStyles();

  const refContainer = React.useRef<HTMLDivElement | null>(null);

  const [btnVisible, setBtnVisible] = React.useState<boolean>(true);

  const {
    position = 'vertical',
    children,
    size,
    onChangeSize,
    onInit,
    maxSize,
    allowResize = true,
    allowTogglePanel = false,
    reverseExpand = false,
    pane1InvisibleForPrint = false
  } = props;

  React.useEffect(() => {
    if (refContainer.current) {
      onInit(refContainer.current);
    }
  }, []);

  const pane1Style: React.CSSProperties = {
    overflow: 'hidden',
    paddingRight: '5px',
  };

  const onDragFinished = (value: number) => {
    onChangeSize(value);
    setBtnVisible(true);
  };

  const onDragStarted = () => {
    setBtnVisible(false);
  };

  const splitterClosed = React.useMemo(() => size < 10, [size]);

  const splitterRight = React.useMemo(() => size === maxSize, [size, maxSize]);

  const leftPosition = React.useMemo(() => {
    if (position === 'horizontal') {
      return '50%';
    }

    let value = size - 15;

    if (splitterClosed) {
      value = value + 10;
    }

    return `${value}px`;
  }, [size, splitterClosed, position]);

  const topPosition = React.useMemo(() => {
    if (position === 'vertical') {
      return '50%';
    }

    let value = size - 28;

    if (splitterClosed) {
      value = value + 11;
    }

    return `${value}px`;
  }, [size, splitterClosed, position]);

  return (
    <div className={classes.root} ref={refContainer}>
      {allowTogglePanel && btnVisible && allowResize && (
        <AppTooltip title={(splitterClosed || splitterRight) ? 'Показать панель' : 'Скрыть панель'}>
          <Box
            className={classes.btn}
            style={{ left: leftPosition, top: topPosition, width: position === 'vertical' ? '12px' : '20px' }}
            color='primary'
            onClick={props.onTogglePanel}
          >
            {
              splitterClosed
                ? (position === 'vertical' ? <ArrowRightIcon /> : <ArrowDropDownIcon />)
                : (position === 'vertical' ? (reverseExpand && !splitterRight ? <ArrowRightIcon /> : <ArrowLeftIcon />)
                  : (reverseExpand && !splitterRight ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                )
            }
          </Box>
        </AppTooltip>
      )}
      <SplitPane
        className={pane1InvisibleForPrint ? classes.pane : ''}
        size={size}
        minSize={5}
        maxSize={maxSize}
        split={position}
        resizerStyle={{ opacity: Number(allowResize), zIndex: 999 }}
        pane1Style={pane1Style}
        onDragStarted={onDragStarted}
        onDragFinished={onDragFinished}
      >
        {children[0]}
        {children[1]}
      </SplitPane>
    </div>
  );
};

export default AppSplitPane;
