import { RelationMapNode } from '@core/classes/relation-map-node';
import { RelationMapLink } from '@core/classes/relation-map-link';

export type RelationMapDto = {
  nodes: RelationMapNode[];
  links: RelationMapLink[];
};

export type NodeRawFields = NodeType & Partial<NodePersonType> & Partial<NodeOrganizationType>;

export type NodeType = {
  id: string;
  type: NodeElementType;
  linkLevel: number;
  foreignAgentFlag?: Nullable<boolean>;
  foreignAgentEndDate?: Nullable<string>;
  foreignAgentConfirmation?: Nullable<string>;
  outputConfirmation?: Nullable<string>;
  isInput?: Nullable<boolean>;
  nodeSource?: NodeSourceType[];
  isClusterNode?: Nullable<boolean>;
  isYellowPressCentralNode?: Nullable<boolean>;
  participatesInCycles?: Nullable<number>[];
  yellowPressData?: YellowPressData[];
  comment?: Nullable<string>[];
  techInfo?: Nullable<TechInfo>;
};

export type NodePersonType = {
  sex?: Nullable<SexType>;
  birthDate?: Nullable<string>;
  birthPlace?: Nullable<string>;
  isDead?: Nullable<boolean>;
  isClient: Nullable<boolean>;
  isJudge: Nullable<boolean>;
  indexFaceProfileGuid?: Nullable<string>;

  fio: Fio[];
  region: Region[];
  personInn: Nullable<string>[];
  address: Nullable<string>[];
  study: Study[];
  socialUrl: Nullable<string>[];
  phone: Nullable<string>[];
  email: Nullable<string>[];
  photo: Photo[];
  sudAkt: SudAkt[];
};

export type NodeOrganizationType = {
  name: string;
  ogrn?: Nullable<string>;
  inn?: Nullable<string>;
  kpp?: Nullable<string>;
  sparkId?: Nullable<string>;

  knownName: Nullable<string>[];
};

export type NodeElementType = 'NodePersonType' | 'NodeOrganizationType';

export type NodeSourceType =
  'Core'
  | 'ExpansionSystem'
  | 'YellowPress'
  | 'ForeignAgents'
  | 'ForeignAgentsExtendedBySpark'
  | 'SocialNetworks'
  | 'Licensing'
  | 'StateContractsM'
  | 'StateContractsC'
  | 'StateContractsP'
  | 'StateContractsExtendedBySpark'
  | 'JudgmentsM'
  | 'JudgmentsD';

export type TechInfo = {
  defineCondition: Nullable<string>[]
}

export type SexType = 'Male' | 'Female';

export type Fio = {
  fullName?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  current: boolean;
  techInfo?: Nullable<FioTechInfo>;
}

export type FioTechInfo = {
  accuracy?: Nullable<number>;
  lastNameToken: string;
  middleNameToken?: Nullable<string>;
  firstNameInitial?: Nullable<string>;
  middleNameInitial?: Nullable<string>;
  method: Nullable<string>;
}

export type Region = {
  code?: Nullable<number>;
  name?: Nullable<string>;
}

export type Study = {
  name: string;
  regionName?: Nullable<string>;
  year: Nullable<number>[]; 
}

export type Photo = {
  id: string;
  fileName: string;
  path: string;
}

export type SudAkt = {
  content: string;
  address?: Nullable<string>;
  issueOrgan?: Nullable<string>;
  kadastralNumber?: Nullable<string>;
  name?: Nullable<string>;
  number?: Nullable<string>;
  series?: Nullable<string>;
}

export type LinkRawFields = LinkBaseType & Partial<LinkWorkType> & Partial<LinkOtherOutputType>;

export type LinkBaseType = {
  id?: string;
  id1: string;
  id2: string;
  type: Nullable<LinkElementType>;
  linkWeight?: Nullable<number>;
  linkSource?: Nullable<LinkSourceType>;

  year?: Nullable<number>[]; 
  comment?: Nullable<string>[];
};

export type LinkWorkType = {
  position?: Nullable<string>;
  location?: Nullable<string>;
  isJudge?: Nullable<boolean>;
  incomeLevel?: Nullable<IncomeLevelType>;
  future?: Nullable<boolean>;
};

export type LinkOtherType = {
  linkType: LinkTypeClassifier;
  linkDescription?: Nullable<string>;
};

export type LinkOtherOutputType = LinkOtherType & {
  licenseData: LicenseData[];
  yellowPressData: YellowPressData[];
  contractData: ContractData[];
  judgmentData: JudgmentData[];
  socialNetworkProfileData: SocialNetworkProfileData[];
  director: Director[];
  founderOrganization: FounderOrganization[];
  founderPerson: FounderPerson[];
  sharerOrganization: SharerOrganization[];
  sharerPerson: SharerPerson[];
  communicationCircleData?: Nullable<CommunicationCircleData>;
};

export type LinkElementType = 'LinkWorkType' | 'LinkOtherOutputType';

export type IncomeLevelType = 'Low' | 'High';

export const LinkTypes = [
  'Relative',
  'WifeHusband',
  'ExWifeHusband',
  'Child',
  'Parent',
  'ParentChild',
  'Sibling',
  'ParentChildInlaw',
  'NextOfKin',
  'GrandParentChild',
  'GodParentChild',
  'Friend',
  'Colleague',
  'Lovers',
  'Founder',
  'Director',
  'Judgment',
  'GeoLocation',
  'SocialNetwork',
  'License',
  'IncomeSource',
  'Contract',
  'Affiliation',
  'Rejected',
  'Structure',
  'Namesake',
  'FullNamesake',
  'PotentiallyFullNamesake',
  'PotentiallySiblings',
  'PotentiallyParentChild',
  'Similar',
  'SubsystemRequest',
  'LowPotentiallySiblings',
  'LowPotentiallyParentChild',
  'FirstLastNamesake',
  'PotentiallyFirstLastNamesake',
  'LastNamesake',
  'FirstNamesakeSimilar',
  'FirstNamesake',
  'SimilarFIO',
  'Common'
];

export type LinkTypeClassifier = typeof LinkTypes[number];

export type LinkSourceType =
  'YellowPress'
  | 'StateContracts'
  | 'Judgments'
  | 'ForeignAgents'
  | 'SocialNetworks'
  | 'Licensing'
  | 'Spark'
  | 'Core'
  | 'Identification'
  | 'Undefined';

export type LicenseData = {
  number?: Nullable<string>;
  date?: Nullable<string>;
  expiredDate?: Nullable<string>;
  activity?: Nullable<string>;
};

export type YellowPressData = {
  yPStock?: Nullable<string>;
  yPSourceType?: Nullable<string>;
  yPSourceAccuracy?: Nullable<string>;
  copies?: Nullable<number>;

  documentInfo: Nullable<DocumentInfo>;
  file: FileType[];
  snippet: SnippetType[];
  rubrics: Rubric[];
};

export type ContractData = {
  contractId?: Nullable<string>;
  contractNumber?: Nullable<string>;
  contractDate?: Nullable<string>;
  price?: Nullable<number>;
  side1Role?: Nullable<ContractRoleType>;
  side2Role?: Nullable<ContractRoleType>;
  isCorruption?: Nullable<boolean>;
  purchaseUrl?: Nullable<string>;
  purchaseId?: Nullable<string>;

  file: FileType[];
  snippet: SnippetType[];
  corruption: CorruptionInfoType[];
};

export type JudgmentData = {
  judgmentNumber?: Nullable<string>;
  judgmentDate?: Nullable<string>;
  judge?: Nullable<string>;
  side1Role?: Nullable<CourtRoleType>;
  side2Role?: Nullable<CourtRoleType>;
  courtName?: Nullable<string>;
  judgmentType?: Nullable<string>;
  courtDecisionUrl?: Nullable<string>;

  file: FileType[];
  snippet: SnippetType[];
};

export type SocialNetworkProfileData = {
  profileName?: Nullable<string>;
  url?: Nullable<string>;
  phoneNumber?: Nullable<string>;
  birthDate?: Nullable<string>;
  birthPlace?: Nullable<string>;

  fio: Fio[];
  imageUrl?: Nullable<string>;
  imageFile?: Nullable<FileType>;
};

export type Director = {
  directorName?: Nullable<string>;
  directorInn?: Nullable<string>;
};

export type FounderOrganization = {
  founderOrgName?: Nullable<string>;
  founderOrgInn?: Nullable<string>;
  founderOrgOgrn?: Nullable<string>;
};

export type FounderPerson = {
  founderPersonName?: Nullable<string>;
  founderPersonInn?: Nullable<string>;
};

export type SharerOrganization = {
  sharerOrgName?: Nullable<string>;
  sharerOrgInn?: Nullable<string>;
  sharerOrgOgrn?: Nullable<string>;
};

export type SharerPerson = {
  sharerName?: Nullable<string>;
  sharerInn?: Nullable<string>;
};

export type CommunicationCircleData = {
  side1Role?: Nullable<string>;
  side2Role?: Nullable<string>;
};

export type DocumentInfo = {
  name?: Nullable<string>;
  publicationDate?: Nullable<string>;
  loadDate?: Nullable<string>;
  webSite?: Nullable<string>;
  url?: Nullable<string>;
};

export type FileType = {
  id?: Nullable<string>;
  fileName?: Nullable<string>;
  filePath?: Nullable<string>;
  filePathInFES?: Nullable<string>;
  sourceFileName?: Nullable<string>;
};

export type SnippetType = {
  snippetValue: SnippetValue[];
};

export type SnippetValue = {
  snippetText: string;
  startPosition?: Nullable<string>;

  match: Nullable<string>[];
};

export type Rubric = {
  rubric: Nullable<string>;
};

export type ContractRoleType =
  'Placer'
  | 'Customer'
  | 'Placer_Customer'
  | 'Supplier'
  | 'Representative';

export type CorruptionInfoType = {
  corruptionTypeValue?: Nullable<CorruptionType>;
  corruptionDescription?: Nullable<string>;
};

export const CorruptionTypes = [
  'PriceDuration',
  'DifferentSubject',
  'OtherActivity',
  'ShortDuration',
  'SupplierRegistration',
  'PriceTurnover'
];

export type CorruptionType = typeof CorruptionTypes[number];

export type CourtRoleType =
  'Plaintiff'
  | 'Defendant'
  | 'Court'
  | 'Third'
  | 'Prosecutor'
  | 'ContributingPerson'
  | 'Judge'
  | 'Lawyer'
  | 'Other'

export type DetailCard = {
  open: boolean;
  title: string;
  text: string;
};

export type NodePosition = {
  x: number;
  y: number;
  xd: number;
  yd: number;
};
