import React, { SyntheticEvent, useCallback, useState } from 'react';
import { TextField } from '@material-ui/core';
import { preciseRound } from '@core/utils/number-helpers';
import './styles.scss';

interface INumberFieldProps {
  htmlId?: string;
  autoFocus?: boolean;
  label?: string;
  value: string;
  min: number;
  max: number;
  disabled?: boolean;
  error?: boolean;
  precision?: number;
  step?: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const validKeys: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'Delete', '.', 'ArrowRight', 'ArrowLeft', 'Tab'];

const NumberField = (props: INumberFieldProps) => {
  const { step = 1 } = props;

  const refInput = React.useRef<Nullable<HTMLInputElement>>(null);

  const [focused, setFocused] = useState<boolean>(false);

  React.useLayoutEffect(() => {
    if (props.autoFocus) {
      setTimeout(() => {
        refInput.current?.focus();
        refInput.current?.setSelectionRange(0, refInput.current?.value?.length);
      }, 150);
    }
  }, [props.autoFocus]);

  const formatDecimal = (value: string) => {
    if (props.precision === undefined) {
      return value;
    }

    const [_, double] = value.split('.');

    const roundedValue = preciseRound(Number(value), props.precision).toString();

    if (!double || double.length === 0 || roundedValue.length < value.length) {
      return value;
    }

    return roundedValue;
  };

  const onChange = (value: string) => {
    if (Number(value) < 0) {
      props.onChange('0');
    } else {
      const [_, double] = value.split('.');

      if (double && props.precision && double.length > props.precision) {
        return;
      }

      props.onChange(formatDecimal(value));
    }
  };

  const onChangeInput = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    onChange(value);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;

    if (validKeys.includes(key) === false) {
      event.preventDefault();
      if (key === 'Enter') {
        validate();
      }
    }
  };

  const formatter = (value: number) => {
    if (props.step === undefined) {
      return value;
    }

    const [_, double] = props.step.toString().split('.');

    if (double === undefined) {
      return value;
    }

    const digit: number = double.length;

    return value.toFixed(digit);
  };

  const onBlur = () => {
    validate();

    setFocused(false);

    if (props.onBlur !== undefined) {
      props.onBlur();
    }
  };

  const validate = () => {
    if (Number(props.value) > props.max) {
      props.onChange(props.max.toString());
    }

    if (Number(props.value) < props.min) {
      props.onChange(props.min.toString());
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onWheel = useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      if (focused === false) {
        return;
      }

      let value;

      if (event.deltaY > 0) {
        value = Number(props.value) - step;
      } else {
        value = Number(props.value) + step;
      }

      const newValue = formatter(value);

      onChange(newValue.toString());
    },
    [focused, props.value],
  );

  return (
    <div onWheel={onWheel} className='number-field'>
      <TextField
        id={props.htmlId}
        variant='outlined'
        size='small'
        disabled={Boolean(props.disabled)}
        label={props.label}
        placeholder={props.label}
        value={props.value}
        error={Boolean(props.error)}
        onChange={onChangeInput}
        style={{ width: '100%' }}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        type='text'
        inputRef={refInput}
      />
    </div>
  );
};

export default NumberField;
