import React, { ReactNode, SyntheticEvent } from 'react';
import { Box, createStyles, IconButton, Theme } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import './styles.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ColDef } from 'ag-grid-community';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppTooltip from '@components/AppTooltip';
import FunnelIcon from '@components/Icons/FunnelIcon';
import BackupIcon from '@material-ui/icons/Backup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { TableServerControls } from '@components/SmartTable/domain/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      padding: 0,
    },
    control: {
      margin: 0,
      width: '100%',
      padding: '0 10px 0 0',
    },
    icon: {
      position: 'relative',
      color: '#000',
      width: '30px',
      height: '30px',
      '& svg': {
        fontSize: '20px',
      },
    },
    filter: {
      '& svg': {
        fontSize: '16px',
      },
    },
    cancel: {
      position: 'absolute',
      color: '#000',
      '& svg': {
        fontSize: '17px',
      },
    },
    paper: {
      maxHeight: '300px',
    },
    export: {
      color: '#000',
    },
  }),
);

type Props = {
  tableServerControls?: TableServerControls;
  columnList: ColDef[];
  hiddenColumnsIds: string[];
  sortActive: boolean;
  activeFilters: boolean;
  children?: ReactNode;
  dropSort?: () => void;
  dropFilters?: () => void;
  toggleVisibleColumn: (colId: string | null) => void;
  onExport?: () => void;
}

const SmartTableSettingsPanel: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const [refMenu, setRefMenu] = React.useState<null | HTMLElement>(null);

  const handleCloseMenu = () => setRefMenu(null);

  const openColumnsSettings = (event: SyntheticEvent) => setRefMenu(event.currentTarget as HTMLElement);

  const toggleVisible = (col: ColDef) => props.toggleVisibleColumn(col ? col.colId || null : null);

  const sortActive = props.sortActive || props.tableServerControls?.sort;

  const activeFilters = props.activeFilters || props.tableServerControls?.filter;

  return (
    <div className='filters'>
      <div className='filters-components'>
        {props.children}
      </div>
      <div className='filters-inner'>
        {props.onExport && <AppTooltip title='Экспорт в таблицу Excel'>
          <IconButton aria-label='export' onClick={props.onExport} size='small'>
            <BackupIcon fontSize='small' className={classes.export} />
          </IconButton>
        </AppTooltip>}

        <AppTooltip title='Сбросить сортировку'>
          <IconButton className={classes.icon} onClick={props.dropSort}>
            <SwapVertIcon />

            {sortActive && (
              <Box top='-9px' right='-1px' className={classes.cancel}>
                <HighlightOffIcon />
              </Box>
            )}
          </IconButton>
        </AppTooltip>

        <AppTooltip title='Сбросить значения фильтров'>
          <IconButton className={classes.icon} onClick={props.dropFilters}>
            <FunnelIcon small />

            {activeFilters && (
              <Box top='-9px' right='-7px' className={classes.cancel}>
                <HighlightOffIcon />
              </Box>
            )}
          </IconButton>
        </AppTooltip>

        <AppTooltip title='Настройки колонок'>
          <IconButton className={classes.icon} onClick={openColumnsSettings}>
            <SettingsIcon />
          </IconButton>
        </AppTooltip>
      </div>

      <Menu anchorEl={refMenu} open={Boolean(refMenu)} classes={{ paper: classes.paper }} onClose={handleCloseMenu}>
        {props.columnList.map((col, i) =>
          col && col.colId && !col.lockPosition ? (
            <MenuItem key={i} className={classes.menuItem}>
              <FormControlLabel
                className={classes.control}
                control={
                  <Checkbox
                    color='primary'
                    checked={props.hiddenColumnsIds.includes(col.colId) === false}
                    onChange={() => toggleVisible(col)}
                  />
                }
                label={col.headerName}
              />
            </MenuItem>
          ) : null,
        )}
      </Menu>
    </div>
  );
};

export default SmartTableSettingsPanel;
