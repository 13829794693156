export enum DocumentEventsGraphTypes {
  CLICK_NODE = 'CLICK_NODE',
  CLICK_LINK = 'CLICK_LINK',
  DOUBLE_CLICK_NODE = 'DOUBLE_CLICK_NODE',
  DOUBLE_CLICK_LINK = 'DOUBLE_CLICK_LINK',
  HIDDEN_NODES = 'HIDDEN_NODES',
  HIDDEN_LINKS = 'HIDDEN_LINKS',
  HIDDEN_LINKS_RELATED = 'HIDDEN_LINKS_RELATED',
  SELECTED_NODES = 'SELECTED_NODES',
  SELECTED_LINKS = 'SELECTED_LINKS',
  SAVE_FILE = 'SAVE_FILE',
  EDIT_NODE = 'EDIT_NODE',
  EDIT_LINK = 'EDIT_LINK',
  RESPONSE_POSITIONS = 'RESPONSE_POSITIONS',
  CHANGE_OPTIONS = 'CHANGE_OPTIONS',
  ZOOM_CHANGED = 'ZOOM_CHANGED',
}

export class DocumentEventsGraph {
  triggerEvent = (key: DocumentEventsGraphTypes, data: unknown) => {
    const event = new CustomEvent(key, {
      detail: {
        data,
      },
    });

    document.dispatchEvent(event);
  };

  addListener = (key: DocumentEventsGraphTypes, callback: (event: unknown) => any) => {
    document.addEventListener(key, callback);
  };

  removeListener = (key: DocumentEventsGraphTypes, callback: (event: unknown) => any) => {
    document.removeEventListener(key, callback);
  };
}
