import React from 'react';
import { LinkRawFields } from '@core/models/relation-map-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuProps, Select, TextField } from '@material-ui/core';
import AppButton from '@components/AppButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import LinkProperties from './components/LinkProperties';
import { useLinkEditForm } from './hooks/useLinkEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: '12px 10px 10px 10px',
    },
    content: {
      padding: '0 8px',
      width: '800px',
    }
  }),
);

export interface IEditLinkDialogProps {
  fields: { id: string, fields: LinkRawFields } | null;
  onSave: (value: LinkRawFields) => void;
  onCancel: () => void;
}

const CreateLinkDialog = (props: IEditLinkDialogProps) => {

  const { fields, onSave, onCancel } = props;

  const linkId = fields ? fields.id : '';

  const linkFields = fields ? fields.fields : null;

  const classes = useStyles();

  const isCreate = !linkFields?.type;

  const {
    formValue,
    validation,
    formTouched,
    onChangeForm,
    onSaveForm,
  } = useLinkEditForm(onSave, linkFields);

  const save = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onSaveForm();
  }

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="lg">
      <form onSubmit={save}>
        <DialogTitle className={classes.title}>{isCreate ? 'Создание связи' : 'Редактирование связи'}</DialogTitle>
        <DialogContent className={classes.content}>
          <LinkProperties
            isCreate={isCreate}
            fields={linkFields}
            formValue={formValue}
            onChangeForm={onChangeForm}
            validation={validation}  
          />
        </DialogContent>
        <DialogActions>
          <Zoom in={isCreate ? true : formTouched}>
            <div>
              <AppButton buttonType="primary" htmlType='submit'>
                Сохранить
              </AppButton>
            </div>
          </Zoom>
          <AppButton buttonType="secondary" onClick={onCancel}>
            Отмена
          </AppButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateLinkDialog;
