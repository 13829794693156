import React, { ReactNode, useState } from 'react';
import TextCell from '@components/SmartTable/components/TextCell';
import { IRowHeight } from '@core/interfaces/row-height';
import { ISmartTableDto } from '@core/models/smart-table.interface';
import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Zoom from '@material-ui/core/Zoom';
import {
    CellClickedEvent,
    CellValueChangedEvent, ColDef,
    Column,
    ColumnApi,
    ColumnMovedEvent,
    ColumnResizedEvent, DragStartedEvent, FilterChangedEvent,
    GridApi,
    GridReadyEvent,
    IGetRowsParams,
    RowDataChangedEvent,
    RowNode,
    SortChangedEvent
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import debounce from 'lodash/debounce';
import AppButton from '../AppButton';
import SmartTableEdgeName from './components/SmartTableEdge';
import SmartTableFilter from './components/SmartTableFilter';
import SmartTableHeader from './components/SmartTableHeader';
import SmartTableNodeName from './components/SmartTableNodeName';
import SmartTableSettingsPanel from './components/SmartTableSettingsPanel';
import { agGridLocale } from './domain/ag-grid.locale';
import { ColumnsDefSettings } from './domain/columns-def-settings';
import tableInitConfig from './domain/config';
import { TableServerControls } from './domain/types';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      backgroundColor: theme.palette.secondary.light
    },
    hideGhost: {
      display: 'none!important'
    },
    zIndexStyle: {
      zIndex: 15
    }
  }),
);

const state = {
  frameworkComponents: {
    agColumnHeader: SmartTableHeader,
    smartTableFilter: SmartTableFilter,
    smartTableEdgeName: SmartTableEdgeName,
    smartTableNodeName: SmartTableNodeName,
    TextCell: TextCell,
  },
  icons: {
    menu: '<span class="ag-icon ag-icon-filter"/>',
  },
};

interface ISmartTableProps {
  totalItems?: number;
  tableConfig?: ISmartTableDto[];
  preventSelectRow?: boolean;
  columnsDef: ColDef[];
  selectedRowIds?: any[];
  tableServerControls?: TableServerControls;
  rowsData?: any[];
  cellComponents?: Record<string, React.ReactNode>;
  rowBuffer?: number;
  parentWidth?: number;
  allowDelete?: boolean;
  hideTotalCount?: boolean;
  isRowSelectable?: (row: RowNode) => boolean;
  rowsHeight?: IRowHeight;
  fetchServerData?: (params: IGetRowsParams) => any;
  rowSelection?: 'multiple' | 'single';
  rowClassRules?: { [cssClassName: string]: (((params: any) => boolean) | string); };
  rowMultiSelectWithClick?: boolean;
  preventSelectionHandle?: (row: any) => boolean;
  children?: ReactNode;
  onSelectRows?: (rows: any[]) => void;
  onDoubleClickRow?: (row: any) => void;
  onChangeTableConfig?: (config: ISmartTableDto[]) => void;
  onChangeCellValue?: (row: any) => void;
  onDeleteRows?: () => void;
  onClickCell?: (colId: string, row: any) => void;
  onChangeSort?: (colId: string, sort: string | null) => void;
  onExport?: () => void;
  onDropFilters?: () => void;
  zIndexProp?: boolean;
}

const tableConfiguration = new ColumnsDefSettings();

const SmartTable = (props: ISmartTableProps) => {
  const [activeFiltersStatus, setActiveFiltersStatus] = React.useState<boolean>(false);

  const [sortActive, setSortActive] = React.useState<boolean>(false);

  const [hiddenColumnsIds, setHiddenColumnsIds] = React.useState<string[]>([]);

  const classes = useStyles();

  const [initialized, setInitialized] = React.useState<boolean>(false);

  const refWrapper = React.useRef<HTMLDivElement>(null);

  const gridRef = React.useRef<HTMLDivElement>(null);

  const gridApiRef = React.useRef<GridApi | null>(null);

  const gridColumnApiRef = React.useRef<ColumnApi | null>(null);

  const [countTitle, setCountTitle] = useState<number>(0);

  const fitColumns = () => {
    if (!gridApiRef.current || !gridColumnApiRef.current || !gridRef.current) {
      return;
    }

    const actualWidth = gridColumnApiRef.current.getAllColumns().reduce((accumulator: number, col: Column) => {
      return accumulator + (col.isVisible() ? col.getActualWidth() : 0);
    }, 0);

    if (actualWidth < gridRef.current.clientWidth) {
      gridApiRef.current?.sizeColumnsToFit();
    }
  };

  const resize = () => {
    fitColumns();
  };

  React.useLayoutEffect(() => {
    if (initialized) {
      fitColumns();
    }
  }, [initialized])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', debounce(resize, 300));

    return () => window.removeEventListener('resize', debounce(resize, 300));
  }, []);

  React.useEffect(() => {
    if (props.parentWidth === undefined) {
      return;
    }

    resize();
  }, [props.parentWidth]);

  const colsDef: ColDef[] = React.useMemo(() => {
    if (props.tableConfig !== undefined) {
      tableConfiguration.colsDef = props.columnsDef;
      tableConfiguration.tableConfig = props.tableConfig;
      setHiddenColumnsIds(tableConfiguration.hiddenColumnsIds);
      return tableConfiguration.colsDefFromSettings();
    }

    return props.columnsDef;
  }, [props.tableConfig, props.columnsDef]);

  React.useEffect(() => {
    if (!gridApiRef.current || !initialized || props.totalItems !== undefined) {
      return;
    }

    setCountTitle(gridApiRef.current.getDisplayedRowCount());
  }, [props.rowsData, initialized]);

  React.useLayoutEffect(() => {
    if (!gridApiRef.current || props.selectedRowIds === undefined || !initialized) {
      return;
    }

    if (props.selectedRowIds.length === 0 && gridApiRef.current?.getSelectedRows().length > 0) {
      gridApiRef.current?.deselectAll();
    }

    const ids = props.selectedRowIds;

    if (props.selectedRowIds.length === 0) {
      gridApiRef.current?.deselectAll();
    } else {
      gridApiRef.current.forEachNode((node) => {
        const id: string = node.data['id'];
        node.setSelected(ids.includes(id) === true);
      });
    }
  }, [props.selectedRowIds, props.rowsData, initialized]);

  const cellComponents = React.useMemo(() => {
    if (!props.cellComponents) {
      return state.frameworkComponents;
    }

    return {
      ...state.frameworkComponents,
      ...props.cellComponents,
    }
  }, [props.cellComponents]);

  const initGrid = (params: GridReadyEvent) => {
    gridColumnApiRef.current = params.columnApi;
    gridApiRef.current = params.api;
    setInitialized(true);
  };

  const toggleVisible = (colId: string | null) => {
    if (gridColumnApiRef.current === null || colId === null) {
      return;
    }

    const colsToHide = tableConfiguration.colsDef.filter((col) => !col.lockPosition);

    const cols: Column[] = gridColumnApiRef.current.getAllGridColumns();

    const hiddenColumns = cols.filter((col) => !col.isVisible());

    const toggledColumn = cols.find((col) => col.getColId() === colId);

    if (toggledColumn === undefined) {
      return;
    }

    const isAllowHide: boolean = colsToHide.length - hiddenColumns.length > 1;

    if (toggledColumn.isVisible() === true) {
      if (isAllowHide === true) {
        gridColumnApiRef.current.setColumnVisible(colId, false);
        tableConfiguration.toggleHideColumn(colId, true);
      }
    } else {
      gridColumnApiRef.current.setColumnVisible(colId, true);
      tableConfiguration.toggleHideColumn(colId, false);
    }

    setHiddenColumnsIds(tableConfiguration.hiddenColumnsIds);

    if (props.onChangeTableConfig) {
      props.onChangeTableConfig(tableConfiguration.tableConfig);
    }
  };

  const dropFilters = () => {
    if (!gridApiRef.current) {
      return;
    }

    gridApiRef.current.setFilterModel(null);
    gridApiRef.current.onFilterChanged();
    setTimeout(() => gridApiRef.current && gridApiRef.current.setFilterModel(null), 100);

    if (props.onDropFilters) {
      props.onDropFilters();
    }
  };

  const dropSort = () => {
    if (!gridApiRef.current) {
      return;
    }

    gridApiRef.current.setSortModel(null);
    gridApiRef.current.onSortChanged();
  };

  const selectRow = () => {
    if (gridApiRef.current && props.onSelectRows) {
      const selectedRows = gridApiRef.current.getSelectedRows();

      props.onSelectRows(selectedRows);
    }
  };

  const onDragStart = (event: DragStartedEvent) => {
    const ghost = document.querySelector('.ag-dnd-ghost');
    ghost?.classList.add(classes.hideGhost);
  }

  const onColumnMoved = (event: ColumnMovedEvent) => {
    const { column, toIndex } = event;

    if (column !== null && toIndex !== undefined) {
      tableConfiguration.columnMove(column.getColId(), toIndex);
    }

    if (props.onChangeTableConfig) {
      props.onChangeTableConfig(tableConfiguration.tableConfig);
    }
  };

  const onColumnResize = (event: ColumnResizedEvent) => {

    const { column } = event;

    if (column !== null) {
      tableConfiguration.columnResize(column.getColId(), column.getActualWidth());
    }

    if (props.onChangeTableConfig) {
      props.onChangeTableConfig(tableConfiguration.tableConfig);
    }

    if (gridApiRef.current) {
      gridApiRef.current.resetRowHeights();
    }
  };

  const onRowDataChanged = (event: RowDataChangedEvent) => {
    const { api } = event;

    api.forEachNode((rowNode) => {
      if (rowNode.data.newItem) {
        rowNode.selectThisNode(true);
      }
    });
  };

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    const { data } = event;
    if (props.onChangeCellValue) {
      props.onChangeCellValue(data);
    }
  };

  const onCellClicked = (event: CellClickedEvent) => {
    const { column, node } = event;
    const id = column.getColId();

    if (id !== 'checkbox') {
      node.setSelected(!node.isSelected());
    }

    if (props.onClickCell) {
      props.onClickCell(id, node.data);
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    const { columnApi } = event;

    const hasActiveFilters = columnApi.getAllDisplayedColumns().some((column) => column.isFilterActive());

    setActiveFiltersStatus(hasActiveFilters);

    if (gridApiRef.current) {
      setCountTitle(gridApiRef.current.getDisplayedRowCount());
    }
  };

  const onDeleteRows = () => {
    if (props.onDeleteRows) {
      props.onDeleteRows();
    }
  };

  const onSortChanged = (event: SortChangedEvent) => {
    const { api } = event;

    const model = api.getSortModel();

    setSortActive(model.length > 0);

    if (props.onChangeSort) {
      if (model.length === 0) {
        props.onChangeSort('', null);
      } else {
        props.onChangeSort(model[0].colId, model[0].sort);
      }
    }
  };

  return (
    <div className="smart-table" ref={refWrapper}>
      <div className="smart-table-container">
        <span className="smart-table-header">
          {Boolean(props.hideTotalCount) === false && (
            <span className="count">
              {'Найдено : '} {props.totalItems || countTitle}
            </span>
          )}

          <div className="smart-table__buttons">
            {props.allowDelete === true && (
              <div>
                <Zoom in={props.allowDelete}>
                  <span className="delete-btn">
                    <AppButton buttonType="secondary" onClick={onDeleteRows}>
                      {'Удалить'}
                    </AppButton>
                  </span>
                </Zoom>
              </div>
            )}
          </div>
        </span>

        <SmartTableSettingsPanel
          tableServerControls={props.tableServerControls}
          columnList={props.columnsDef}
          hiddenColumnsIds={hiddenColumnsIds}
          sortActive={sortActive}
          activeFilters={activeFiltersStatus}
          toggleVisibleColumn={toggleVisible}
          dropFilters={dropFilters}
          dropSort={dropSort}
          onExport={props.onExport}
        >{props.children}</SmartTableSettingsPanel>
      </div>

      <div className={`ag-theme-alpine grid ${classes.grid} ${props.zIndexProp ? classes.zIndexStyle : ''}`} ref={gridRef}>
        <AgGridReact
          suppressDragLeaveHidesColumns={true}
          icons={state.icons}
          defaultColDef={tableInitConfig.defaultColDef}
          columnDefs={colsDef}
          rowData={props.rowsData}
          rowSelection={props.rowSelection || tableInitConfig.rowSelection}
          frameworkComponents={cellComponents}
          enableCellTextSelection={true}
          localeText={agGridLocale}
          rowMultiSelectWithClick={props.rowMultiSelectWithClick}
          suppressRowClickSelection={true}
          rowBuffer={props.rowBuffer || 10}
          rowClassRules={props.rowClassRules}
          isRowSelectable={props.isRowSelectable}
          onGridReady={initGrid}
          onRowSelected={selectRow}
          onDragStarted={onDragStart}
          onColumnMoved={onColumnMoved}
          onColumnResized={debounce(onColumnResize, 600)}
          onRowDataChanged={onRowDataChanged}
          onCellValueChanged={onCellValueChanged}
          onCellClicked={onCellClicked}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
        />
      </div>
    </div>
  );
};

export default SmartTable;
