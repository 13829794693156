const textComparator = (value1: string, value2: string): number => value1.trim().toLowerCase() > value2.trim().toLowerCase() ? 1 : -1;

const isEmptyValue = (val: any) => val === null || val === undefined;

export const tableComparator = (value1: any, value2: any): number => {
  if (isEmptyValue(value1) && value2) {
    return -1;
  }

  if (value1 && isEmptyValue(value2)) {
    return 1;
  }

  if (isEmptyValue(value1) && isEmptyValue(value2)) {
    return 0;
  }

  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return textComparator(value1, value2);
  }

  if (typeof value1 === 'number' && typeof value2 === 'number') {
    return value1 > value2 ? 1 : -1;
  }

  if (typeof value1 === 'boolean' && typeof value2 === 'boolean') {
    return value1 > value2 ? -1 : 1;
  }

  console.error('Sort Error: Object as sorting element');

  return 0;
}

export const tableObjectComparator = (key: string) => (value1: any, value2: any) => {
  if (isEmptyValue(value1) && value2) {
    return -1;
  }

  if (value1 && isEmptyValue(value2)) {
    return 1;
  }

  if (isEmptyValue(value1) && isEmptyValue(value2)) {
    return 0;
  }

  return tableComparator(value1[key], value2[key]);
};
