import * as React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import AppTooltip from '@components/AppTooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '5px'
    },
  },
  input: {
    display: 'none',
  },
}));

interface IFileActionsProps {
  onLoad: (file: File) => void;
  onSave?: (fileName: string | null) => void;
}

const FileActions: React.FC<IFileActionsProps> = ({ onLoad, onSave }) => {

  const classes = useStyles();
  const [selectedFile, setSelectedFile] = React.useState<any>(null);

  const handleLoad = ({ target }: any) => {
    setSelectedFile(target.files[0]);
    onLoad(target.files[0]);
  };

  const handleSubmit = () => {
    if (onSave) {
      onSave(selectedFile);
    }
  };

  return (
    <div className={classes.root}>
      <input
        accept="application/json, application/xml"
        className={classes.input}
        id='fileLoad'
        type='file'
        onChange={handleLoad}
      />
      <AppTooltip title='Выберите файл'>
        <label htmlFor='fileLoad'>
          <IconButton
            size='small'
            component='span'
          >
            <PublishIcon fontSize='small' />
          </IconButton>
        </label>
      </AppTooltip>
      <label>{selectedFile ? selectedFile.name : 'Выберите файл. . .'}</label>
      <AppTooltip title='Сохранить'>
        <div>
          <IconButton
            size='small'
            disabled={!selectedFile}
            onClick={() => handleSubmit()}
          >
            <GetAppIcon fontSize='small' />
          </IconButton>
        </div>
      </AppTooltip>
    </div>
  );
};

export default FileActions;
