import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import FunnelEmptyIcon from '../../../Icons/FunnelEmptyIcon';
import FunnelIcon from '../../../Icons/FunnelIcon';
import AppTooltip from '../../../AppTooltip';

const SmartTableHeader = (props: any) => {

  const [ascSort, setAscSort] = useState('inactive');

  const [descSort, setDescSort] = useState('inactive');

  const [noSort, setNoSort] = useState('inactive');

  const [activeFilter, setActiveFilter] = useState<boolean>(false);

  const refButton = useRef(null);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');

    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');

    setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive');
  };

  const onSortRequested = (order: any, event: any) => {
    props.setSort(order, event.shiftKey);
  };

  React.useEffect(() => {
    if (Boolean(props.column.filterActive)) {
      setActiveFilter(true);
    }
  }, [props]);

  const filterChanged = (event: { column: { colId: string; filterActive: boolean } }) => {
    setActiveFilter(props.column.colId === event.column.colId && event.column.filterActive);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();

    props.column.addEventListener('filterChanged', filterChanged);

    return () => {
      props.column.removeEventListener('sortChanged', onSortChanged);
      props.column.removeEventListener('filterChanged', filterChanged);
    };
  }, []);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div ref={refButton} className="sort-label" onClick={onMenuClicked}>
        {activeFilter ? <FunnelIcon small={true} /> : <FunnelEmptyIcon />}
      </div>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div style={{ display: 'inline-block' }}>
        <div
          onClick={(event) => onSortRequested('desc', event)}
          onTouchEnd={(event) => onSortRequested('desc', event)}
          className={`sort-label ${ascSort}`}
        >
          <ArrowUpwardIcon />
        </div>
        <div
          onClick={(event) => onSortRequested('', event)}
          onTouchEnd={(event) => onSortRequested('', event)}
          className={`sort-label ${descSort}`}
        >
          <ArrowDownwardIcon />
        </div>
        <div
          onClick={(event) => onSortRequested('asc', event)}
          onTouchEnd={(event) => onSortRequested('asc', event)}
          className={`sort-label ${noSort}`}
        >
          <SwapVertIcon />
        </div>
      </div>
    );
  }

  return (
    <AppTooltip title={props['column']['colDef']['tooltipComponent'] || ''}>
      <div className="s-header">
        <div className="s-header__name">{props.displayName}</div>
        {menu}
        {sort}
      </div>
    </AppTooltip>
  );
};

export default SmartTableHeader;
