import React from 'react';

type ValidationSchema<T> = Record<keyof T & string, boolean>;

type ValidationRules<T, K> = Record<keyof K & string, ((val: T) => boolean) | null>;

export const useForm = <T, K>(initialValue: T, rulesValidation: ValidationRules<T, K>) => {

  const [value, setValue] = React.useState<T>(initialValue);

  const [isValid, setIsValid] = React.useState<boolean | null>(null);

  const [formTouched, setFormTouched] = React.useState<boolean>(false);

  const [validation, setValidation] = React.useState<ValidationSchema<T>>({} as ValidationSchema<T>);

  const [initialValidation, setInitialValidation] = React.useState<ValidationSchema<T>>({} as ValidationSchema<T>);

  const initValidation = () => {
    const _validation: ValidationSchema<T> = {} as ValidationSchema<T>;

    Object.keys(rulesValidation).forEach((key) => {
      _validation[key] = true;
    });

    setValidation(_validation);
    setInitialValidation({ ..._validation });
  };

  React.useEffect(() => {
    initValidation();
  }, []);

  React.useEffect(() => {
    setIsValid(validator(validation));
  }, [validation]);

  const validator = (validation: ValidationSchema<T>) => {
    return Object.keys(validation).every((key) => validation[key] === true);
  };

  const setInitialValue = (val: T) => {
    setValue(val);
    initValidation();
    setFormTouched(false);
  };

  const onChange = <V, >(key: keyof T & string, val: V) => {
    setValue((prev) => ({ ...prev, [key]: val }));

    if (isValid === false) {
      setValidation({ ...initialValidation });
    }

    if (formTouched === false) {
      setFormTouched(true);
    }
  };

  const defaultValidator = (value: any) => {
    if (typeof value === 'object' || typeof value === 'number') {
      return value !== null && value !== undefined;
    }

    if (typeof value === 'string') {
      return value.trim().length > 0;
    }

    return false;
  };

  const onValidate = (): boolean => {
    Object.keys(validation).forEach((key) => {
      validation[key] = typeof rulesValidation[key] === 'function'
        ? rulesValidation[key](value)
        : defaultValidator(value[key]);
    });

    const res = { ...validation };
    setValidation(res);

    return validator(res);
  };

  return {
    value,
    validation,
    isValid,
    formTouched,
    setFormTouched,
    setInitialValue,
    onChange,
    onValidate,
  };
};
