// @ts-ignore
import { NIL as NIL_UUID, v4 as uuidv4 } from 'uuid';

export class IdGenerator {
  static getId(): string {
    return uuidv4();
  }

  static getEmptyGuid(): string {
    return NIL_UUID;
  }

  static addIdToField(obj: object, key: string = 'id'): object {
    return {
      ...obj,
      [key]: IdGenerator.getId(),
    };
  }

  static addIdFieldToArray(arr: any[], key: string = 'id'): any[] {
    return arr.map((item) => IdGenerator.addIdToField(item, key));
  }
}

