import { GraphLayout } from "./graph-layout";
import { ObjectAttributes } from "@core/default-settings/object-attributes";
import { CorruptionTypes } from '@core/models/relation-map-types';

export class ObjectGraph {
  GraphData = {};

  Settings = {
    BackColor: "#FFFFFF",
    FixGraphOnDrag: 1,
    FontColor: "#000000",
    FontFace: "Arial",
    FontSize: 8,
    JoinThemesMode: 1,
    LabelsPercent: 95,
    LinkColor: "#E8B1F2",
    MAXLinkCount: 550,
    MAXLinkWeight: 100,
    MAXLinkWidth: 3,
    MAXNodeSize: 30,
    MAXThemeCount: 500,
    MINLinkWeight: 1,
    MINNodeSize: 20,
    MaxLabelLength: 30,
    NodeStrokeColor: "#0393dd",
    ProcessingCoordsMode: 1,
    ShowOnlyConnectedThemes: true,
    SkeletonScale: 50,
    ThemeTypeList: {},
    UseFilteredLinkCounts: true,
    minDocConnFreq: 1,
    minDocThemeFreq: 1
  };

  options;

  graphLayout;

  nodes;

  links;

  constructor() {
    this.graphLayout = new GraphLayout(false);
  }

  init = (nodes, links, keepPositions) => {
    try {
      if (this.graphLayout.graph) {
        this.graphLayout.graph.removeListeners();
      }

      this.graphLayout = new GraphLayout(keepPositions);
      this.setGraphData(nodes, links);
    } catch (err) {
      console.error(err);
    }
  };

  setGraphData(nodes, links) {
    this.nodes = nodes.map((node) => ({
      id: node.id,
      ID: node.id,
      Freq: 10,
      Name: node.name,
      Type: node.type,
      Fields: node.fields,
      Position: node.position
    }));

    this.links = links.map(function (link, i) {
      return {
        id: link.id,
        ID: link.id,
        SourceThemeID: link.id1,
        DestThemeID: link.id2,
        Freq: 5,
        Weight: 5,
        Color: this.setLinkColor(link),
        Fields: link.fields,
      };
    }, this);

    this.prepareGraphData();
  }

  setLinkColor(link) {
    switch (link.fields.linkSource) {
      case 'Core':
        return '#E32322';
      case 'Spark':
        return '#000000';
      case 'YellowPress':
        return '#F3E500';
      case 'StateContracts':
        if (link.fields.contractData && link.fields.contractData.some(data => data.isCorruption === true)) {
          return '#FC600A';
        }
        return '#833D1A';        
      case 'Judgments':
        return '#4424D6';
      case 'ForeignAgents':
        return '#008F5A';
      case 'SocialNetworks':
        return '#0080FF';
      case 'Licensing':
        return '#8601AF';
      case 'Identification':
        switch (link.fields.linkType) {
          case 'Namesake':
            return '#D9E5E8';
          case 'FullNamesake':
          default:
            return '#5FB2C4';
          case 'PotentiallyFullNamesake':
            return '#A6D0D9';
          case 'PotentiallySiblings':
            return '#8AD1BB';
          case 'Similar':
            return '#777777';
        }
      case 'Undefined':
        return '#FF00FF';
      default:
        return '#FF8DCF';
    }
  }

  prepareGraphData() {
    const links = this.links;

    const nodes = this.nodes.sort((a, b) => {
      return a.Name.toLowerCase() == b.Name.toLowerCase()
        ? 0
        : a.Name.toLowerCase() < b.Name.toLowerCase()
          ? -1
          : 1;
    });

    var idMapper = {}, //typeof Dictionnary<int, int>
      minNodeFreq = Number.POSITIVE_INFINITY,
      maxNodeFreq = Number.NEGATIVE_INFINITY,
      minLinkFreq = Number.POSITIVE_INFINITY,
      maxLinkFreq = Number.NEGATIVE_INFINITY;

    for (var i = 0, n = nodes.length; i < n; i++) {
      var node = nodes[i];
      idMapper[node.ID] = i;
      if (minNodeFreq > node.Freq) minNodeFreq = node.Freq;
      if (maxNodeFreq < node.Freq) maxNodeFreq = node.Freq;
    }

    for (let i = 0, n = links.length; i < n; i++) {
        var l = links[i];
        if (minLinkFreq > l.Freq) minLinkFreq = l.Freq;
        if (maxLinkFreq < l.Freq) maxLinkFreq = l.Freq;
    }

    var deltaNodeFreq = maxNodeFreq - minNodeFreq,
        deltaLinkFreq = maxLinkFreq - minLinkFreq;

    for (let i = 0, n = nodes.length; i < n; i++) {
      var node = nodes[i];
      node.icon = ObjectAttributes.getObjectIconFromAssets()[node.Type];
      node.Size = deltaNodeFreq == 0 ? 1 : (node.Freq - minNodeFreq) / deltaNodeFreq;

      const list = ObjectAttributes.defaultList;
      const semNameItem = list.find((item) => item.type === node.Type);
      const semName = semNameItem.value;
      node.title = node.Name;
      node.img = "<img src=\"" + node.icon + "\" class=\"ListItemImg\" title=\"" + semName + "\" />";
    }

    for (let i = 0, n = links.length; i < n; i++) {
      var l = links[i];

      l.FromId = l.SourceThemeID;
      l.ToId = l.DestThemeID;
      l.source = idMapper[l.SourceThemeID];
      l.target = idMapper[l.DestThemeID];
      //l.Color = this.options.semtypesColors[l.LinkType];

      l.title = l.Fields.linkType && l.Fields.linkType.length > 0 ? `Вид связи: ${l.Fields.linkType}` : '';
      l.Size = deltaLinkFreq == 0 ? 1 : ((l.Freq - minLinkFreq) / deltaLinkFreq) * 0.6 + 0.1; //from 0.1 to 0.7
      l.Width = null; //+
    }

    this.graphLayout.loadData({
      Nodes: nodes,
      Links: links
    });
  }
}

export const init = (nodes, links) => {
  try {
    const graphObj = new ObjectGraph();

    graphObj.setGraphData(nodes, links);

    return graphObj;
  } catch (err) {
    console.error(err);
  }
};
