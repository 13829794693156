import { ReactNode } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import { IObjectAttributes } from '../interfaces/object-attributes.interface';

// @ts-ignore
import icon9 from '@assets/icons/relation_map/9.svg';
// @ts-ignore
import icon17 from '@assets/icons/relation_map/17.svg';

export class ObjectAttributes {
  static getObjectIcon(className?: any): { [code: number]: ReactNode } {
    return {
    1: <PersonIcon className={ className } />,
    2: <AccountBalanceIcon className={ className } />
  };
}

  static getObjectIconFromAssets(): { [code: number]: string } {
    return {
      1: icon9,
      2: icon17
    };
  }

  static get defaultList(): IObjectAttributes[] {
    return [
      {
        type: 1,
        order: 1,
        quantity: 1,
        color: '#f29050',
        value: 'ФЛ',
        selected: true,
      },
      {
        type: 2,
        order: 1,
        quantity: 1,
        color: '#5cc9ff',
        value: 'ЮЛ',
        selected: true,
      }
    ];
  }  
}
