import { LinkRawFields } from '@core/models/relation-map-types';
import { convertJsonToDict, stringifyDictionary } from '@core/utils/convert-helpers';
import { IdGenerator } from '@core/utils/id-generator';

export class RelationMapLink {
  public fields;
  public id = '';
  public id1 = '';
  public id2 = '';
  public type = '';
  public data = '';
  public hidden = false;

  constructor(fields: LinkRawFields) {
    this.fields = fields;
    this.id = fields.id || IdGenerator.getId();
    this.id1 = fields.id1;
    this.id2 = fields.id2;
    this.type = fields.type || '';

    this.fillData();
  }

  fillData = () => {
    const values: { [key: string]: string } = {};
    convertJsonToDict(this.fields, this.fields, values);
    this.data = stringifyDictionary(values);
  }
}