import { RelationMapNode } from '@core/classes/relation-map-node';
import { RelationMapLink } from '@core/classes/relation-map-link';
import {
  RelationMapDto,
  NodeType,
  NodePersonType,
  NodeOrganizationType,
  NodeElementType,
  NodeSourceType,
  Fio,
  FioTechInfo,
  Photo,
  Region,
  SexType,
  Study,
  SudAkt,
  TechInfo,
  LinkBaseType,
  LinkWorkType,
  LinkOtherOutputType,
  LinkElementType,
  IncomeLevelType,
  LinkTypeClassifier,
  LinkSourceType,
  LicenseData,
  YellowPressData,
  ContractData,
  JudgmentData,
  SocialNetworkProfileData,
  Director,
  FounderOrganization,
  FounderPerson,
  SharerOrganization,
  SharerPerson,
  CommunicationCircleData,
  DocumentInfo,
  FileType,
  SnippetType,
  SnippetValue,
  Rubric,
  ContractRoleType,
  CorruptionInfoType,
  CorruptionType,
  CourtRoleType,
} from '@core/models/relation-map-types';

export class XmlConverter {
  xmlDoc: Document;
  nsResolver: XPathNSResolver;

  constructor(xmlSource: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlSource, 'application/xml');
    this.xmlDoc = xmlDoc;
    
    const ownerDoc: any = this.xmlDoc.ownerDocument;
    this.nsResolver = xmlDoc.createNSResolver(ownerDoc == null ? xmlDoc.documentElement : ownerDoc.documentElement);
  }

  parse = () => {
    const relationMap: RelationMapDto = {
      nodes: [],
      links: []
    }

    try {
      const nodes = this.getElements('//Node');
      for (const node of nodes) {
        const relationNode = this.parseNodeElement(node);
        relationMap.nodes.push(relationNode);
      }

      const links = this.getElements('//Link');
      for (const link of links) {
        const relationLink = this.parseLinkElement(link);
        relationMap.links.push(relationLink);
      }
    }
    catch (e) {
      alert(`Ошибка при выполнении конвертации xml -> json: ${e}`);
    }

    return relationMap;
  };

  parseNodeElement = (node: Element): RelationMapNode => {
    const baseProps: NodeType = {
      id: node.getAttribute('Id') || '',
      type: <NodeElementType>node.getAttribute('xsi:type') || '',
      linkLevel: Number(node.getAttribute('LinkLevel')) || 0,
      foreignAgentFlag: node.getAttribute('ForeignAgentFlag') ? node.getAttribute('ForeignAgentFlag') === 'true' : null,
      foreignAgentEndDate: node.getAttribute('ForeignAgentEndDate'),
      foreignAgentConfirmation: node.getAttribute('ForeignAgentConfirmation'),
      outputConfirmation: node.getAttribute('OutputConfirmation'),
      isInput: node.getAttribute('IsInput') ? node.getAttribute('IsInput') === 'true' : null,
      nodeSource: this.getElements('./NodeSource', node).map(item => <NodeSourceType>item.textContent),
      isClusterNode: this.selector('./IsClusterNode', XPathResult.STRING_TYPE, node).stringValue ? this.selector('./IsClusterNode', XPathResult.STRING_TYPE, node).stringValue === 'true' : null,
      isYellowPressCentralNode: this.selector('./IsYellowPressCentralNode', XPathResult.STRING_TYPE, node).stringValue ? this.selector('./IsYellowPressCentralNode', XPathResult.STRING_TYPE, node).stringValue === 'true' : null,
      participatesInCycles: this.getElements('./ParticipatesInCycles', node)
        .map(item => item.textContent ? Number(item.textContent) : null),
      yellowPressData: this.getElements('./YellowPressData', node).map(item => {
        const data: YellowPressData = {
          yPStock: item.getAttribute('YPStock'),
          yPSourceType: item.getAttribute('YPSourceType'),
          yPSourceAccuracy: item.getAttribute('YPSourceAccuracy'),
          copies: item.getAttribute('Copies') ? Number(item.getAttribute('Copies')) : null,
          documentInfo: this.getElements('./DocumentInfo', item).map(subitem => {
            const docInfo: DocumentInfo = {
              name: subitem.getAttribute('Name'),
              publicationDate: subitem.getAttribute('PublicationDate'),
              loadDate: subitem.getAttribute('LoadDate'),
              webSite: subitem.getAttribute('WebSite'),
              url: subitem.getAttribute('Url'),
            };

            return docInfo;
          })[0],
          file: this.getElements('./File', item).map(subitem => {
            const fileData: FileType = {
              id: subitem.getAttribute('Id'),
              fileName: subitem.getAttribute('FileName'),
              filePath: subitem.getAttribute('FilePath'),
              filePathInFES: subitem.getAttribute('FilePathInFES'),
              sourceFileName: subitem.getAttribute('SourceFileName'),
            };

            return fileData;
          }),
          snippet: this.getElements('./Snippet', item).map(subitem => {
            const snippetItem = {
              snippetValue: this.getElements('./SnippetValue', subitem).map(snippetSubitem => {
                const snippetData: SnippetValue = {
                  snippetText: snippetSubitem.getAttribute('SnippetText') || '',
                  startPosition: snippetSubitem.getAttribute('StartPosition'),
                  match: this.getElements('./Match', snippetSubitem).map(x => x.textContent),
                };

                return snippetData;
              })
            };

            return snippetItem;
          }),
          rubrics: this.getElements('.//Rubric', item).map(subitem => {
            const rubric: Rubric = {
              rubric: subitem.textContent
            };

            return rubric;
          }),
        };

        return data;
      }),
      comment: this.getElements('./Comment', node).map(item => item.textContent),
      techInfo: this.getElements('./TechInfo', node).map(item => {
        const techInfo: TechInfo = {
          defineCondition: this.getElements('./DefineCondition', item).map(subitem => subitem.textContent)
        };

        return techInfo;
      })[0],
    }

    let personProps: Partial<NodePersonType> = {};
    let orgProps: Partial<NodeOrganizationType> = {};

    if (baseProps.type === 'NodePersonType') {
      personProps = {
        sex: <SexType>node.getAttribute('Sex'),
        birthDate: node.getAttribute('BirthDate'),
        birthPlace: node.getAttribute('BirthPlace'),
        isDead: node.getAttribute('IsDead') ? node.getAttribute('IsDead') === 'true' : null,
        isClient: node.getAttribute('IsClient') ? node.getAttribute('IsClient') === 'true' : null,
        isJudge: node.getAttribute('IsJudge') ? node.getAttribute('IsJudge') === 'true' : null,
        indexFaceProfileGuid: node.getAttribute('IndexFaceProfileGuid'),
        fio: this.getElements('./Fio', node).map(item => {
          const fio: Fio = {
            fullName: item.getAttribute('FullName') || '',
            lastName: item.getAttribute('LastName') || '',
            firstName: item.getAttribute('FirstName') || '',
            middleName: item.getAttribute('MiddleName') || '',
            current: item.getAttribute('Current') === 'true',
            techInfo: this.getElements('./TechInfo', item).map(subitem => {
              const techInfo: FioTechInfo = {
                accuracy: subitem.getAttribute('Accuracy') ? Number(subitem.getAttribute('Accuracy')) : null,
                lastNameToken: subitem.getAttribute('LastNameToken') || '',
                middleNameToken: subitem.getAttribute('MiddleNameToken'),
                firstNameInitial: subitem.getAttribute('FirstNameInitial'),
                middleNameInitial: subitem.getAttribute('MiddleNameInitial'),
                method: subitem.getAttribute('Method')
              };

              return techInfo;
            })[0],
          };

          return fio;
        }),
        region: this.getElements('./Region', node).map(item => {
          const region: Region = {
            code: item.getAttribute('Code') ? Number(item.getAttribute('Code')) : null,
            name: item.getAttribute('Name')
          };

          return region;
        }),
        personInn: this.getElements('./Inn', node).map(item => item.textContent),
        address: this.getElements('./Address', node).map(item => item.textContent),
        study: this.getElements('./Study', node).map((item, i) => {
          const study: Study = {
            name: item.getAttribute('Name') || '',
            regionName: item.getAttribute('Name'),
            year: this.getElements(`./Study[${i}]/Year`).map(item => item.textContent ? Number(item.textContent) : null)
          };

          return study;
        }),
        socialUrl: this.getElements('./SocialUrl', node).map(item => item.textContent),
        phone: this.getElements('./Phone', node).map(item => item.textContent),
        email: this.getElements('./Email', node).map(item => item.textContent),
        photo: this.getElements('./Photo', node).map(item => {
          const photo: Photo = {
            id: item.getAttribute('Id') || '',
            fileName: item.getAttribute('FileName') || '',
            path: item.getAttribute('Path') || ''
          };

          return photo;
        }),
        sudAkt: this.getElements('./SudAkt', node).map(item => {
          const act: SudAkt = {
            content: item.getAttribute('Content') || '',
            address: item.getAttribute('Address'),
            issueOrgan: item.getAttribute('IssueOrgan'),
            kadastralNumber: item.getAttribute('KadastralNumber'),
            name: item.getAttribute('Name'),
            number: item.getAttribute('Number'),
            series: item.getAttribute('Series')
          };

          return act;
        }),
      };
    } else if (baseProps.type === 'NodeOrganizationType') {
      orgProps = {
        name: node.getAttribute('Name') || '',
        ogrn: node.getAttribute('OGRN'),
        inn: node.getAttribute('INN'),
        kpp: node.getAttribute('KPP'),
        sparkId: node.getAttribute('SparkId'),
        knownName: this.getElements('./KnownName', node).map(item => item.textContent),
      }
    }

    const rawFields = { ...baseProps, ...personProps, ...orgProps }
    const relationMapNode = new RelationMapNode(rawFields, null);

    return relationMapNode;
  }

  parseLinkElement = (link: Element): RelationMapLink => {
    const baseProps: LinkBaseType = {
      id1: link.getAttribute('Id1') || '',
      id2: link.getAttribute('Id2') || '',
      type: <LinkElementType>link.getAttribute('xsi:type') || '',
      linkWeight: link.getAttribute('LinkWeight') ? Number(link.getAttribute('LinkWeight')) : null,
      linkSource: <LinkSourceType>link.getAttribute('LinkSource'),
      year: this.getElements('./Year', link).map(item => Number(item.textContent)),
      comment: this.getElements('./Comment', link).map(item => item.textContent),
    }

    let workProps: Partial<LinkWorkType> = {};
    let otherOutputProps: Partial<LinkOtherOutputType> = {};

    if (baseProps.type === 'LinkWorkType') {
      workProps = {
        position: link.getAttribute('Position'),
        location: link.getAttribute('Location'),
        isJudge: link.getAttribute('IsJudge') ? link.getAttribute('IsJudge') === 'true' : null,
        incomeLevel: <IncomeLevelType>link.getAttribute('IncomeLevel'),
        future: link.getAttribute('Future') ? link.getAttribute('Future') === 'true' : null,
      }
    } else if (baseProps.type === 'LinkOtherOutputType') {
      otherOutputProps = {
        linkType: <LinkTypeClassifier>link.getAttribute('LinkType') || '',
        linkDescription: link.getAttribute('LinkDescription'),
        licenseData: this.getElements('./LicenseData', link).map(item => {
          const data: LicenseData = {
            number: item.getAttribute('Number'),
            date: item.getAttribute('Date'),
            expiredDate: item.getAttribute('ExpiredDate'),
            activity: item.getAttribute('Activity'),
          };

          return data;
        }),
        yellowPressData: this.getElements('./YellowPressData', link).map(item => {
          const data: YellowPressData = {
            yPStock: item.getAttribute('YPStock'),
            yPSourceType: item.getAttribute('YPSourceType'),
            yPSourceAccuracy: item.getAttribute('YPSourceAccuracy'),
            copies: item.getAttribute('Copies') ? Number(item.getAttribute('Copies')) : null,
            documentInfo: this.getElements('./DocumentInfo', item).map(subitem => {
              const docInfo: DocumentInfo = {
                name: subitem.getAttribute('Name'),
                publicationDate: subitem.getAttribute('PublicationDate'),
                loadDate: subitem.getAttribute('LoadDate'),
                webSite: subitem.getAttribute('WebSite'),
                url: subitem.getAttribute('Url'),
              };

              return docInfo;
            })[0],
            file: this.getElements('./File', item).map(subitem => {
              const fileData: FileType = {
                id: subitem.getAttribute('Id'),
                fileName: subitem.getAttribute('FileName'),
                filePath: subitem.getAttribute('FilePath'),
                filePathInFES: subitem.getAttribute('FilePathInFES'),
                sourceFileName: subitem.getAttribute('SourceFileName'),
              };

              return fileData;
            }),
            snippet: this.getElements('./Snippet', item).map(subitem => {
              const snippetItem = {
                snippetValue: this.getElements('./SnippetValue', subitem).map(snippetSubitem => {
                  const snippetData: SnippetValue = {
                    snippetText: snippetSubitem.getAttribute('SnippetText') || '',
                    startPosition: snippetSubitem.getAttribute('StartPosition'),
                    match: this.getElements('./Match', snippetSubitem).map(x => x.textContent),
                  };

                  return snippetData;
                })
              };

              return snippetItem;
            }),
            rubrics: this.getElements('.//Rubric', item).map(subitem => {
              const rubric: Rubric = {
                rubric: subitem.textContent
              };

              return rubric;
            }),
          };

          return data;
        }),
        contractData: this.getElements('./ContractData', link).map(item => {
          const data: ContractData = {
            contractId: item.getAttribute('ContractId'),
            contractNumber: item.getAttribute('ContractNumber'),
            contractDate: item.getAttribute('ContractDate'),
            price: item.getAttribute('Price') ? Number(item.getAttribute('Price')) : null,
            side1Role: <ContractRoleType>item.getAttribute('Side1Role'),
            side2Role: <ContractRoleType>item.getAttribute('Side2Role'),
            isCorruption: item.getAttribute('IsCorruption') ? item.getAttribute('IsCorruption') === 'true' : null,
            purchaseUrl: item.getAttribute('PurchaseUrl'),
            purchaseId: item.getAttribute('PurchaseId'),
            file: this.getElements('./File', item).map(subitem => {
              const fileData: FileType = {
                id: subitem.getAttribute('Id'),
                fileName: subitem.getAttribute('FileName'),
                filePath: subitem.getAttribute('FilePath'),
                filePathInFES: subitem.getAttribute('FilePathInFES'),
                sourceFileName: subitem.getAttribute('SourceFileName'),
              };

              return fileData;
            }),
            snippet: this.getElements('./Snippet', item).map(subitem => {
              const snippetItem = {
                snippetValue: this.getElements('./SnippetValue', subitem).map(snippetSubitem => {
                  const snippetData: SnippetValue = {
                    snippetText: snippetSubitem.getAttribute('SnippetText') || '',
                    startPosition: snippetSubitem.getAttribute('StartPosition'),
                    match: this.getElements('./Match', snippetSubitem).map(x => x.textContent),
                  };

                  return snippetData;
                })
              };

              return snippetItem;
            }),
            corruption: this.getElements('./Corruption', item).map(subitem => {
              const corruptionData: CorruptionInfoType = {
                corruptionTypeValue: <CorruptionType>subitem.getAttribute('CorruptionTypeValue'),
                corruptionDescription: subitem.getAttribute('CorruptionDescription'),
              };

              return corruptionData;
            }),
          };

          return data;
        }),
        judgmentData: this.getElements('./JudgmentData', link).map(item => {
          const data: JudgmentData = {
            judgmentNumber: item.getAttribute('JudgmentNumber'),
            judgmentDate: item.getAttribute('JudgmentDate'),
            judge: item.getAttribute('Judge'),
            side1Role: <CourtRoleType>item.getAttribute('Side1Role'),
            side2Role: <CourtRoleType>item.getAttribute('Side2Role'),
            courtName: item.getAttribute('CourtName'),
            judgmentType: item.getAttribute('JudgmentType'),
            courtDecisionUrl: item.getAttribute('CourtDecisionUrl'),
            file: this.getElements('./File', item).map(subitem => {
              const fileData: FileType = {
                id: subitem.getAttribute('Id'),
                fileName: subitem.getAttribute('FileName'),
                filePath: subitem.getAttribute('FilePath'),
                filePathInFES: subitem.getAttribute('FilePathInFES'),
                sourceFileName: subitem.getAttribute('SourceFileName'),
              };

              return fileData;
            }),
            snippet: this.getElements('./Snippet', item).map(subitem => {
              const snippetItem = {
                snippetValue: this.getElements('./SnippetValue', subitem).map(snippetSubitem => {
                  const snippetData: SnippetValue = {
                    snippetText: snippetSubitem.getAttribute('SnippetText') || '',
                    startPosition: snippetSubitem.getAttribute('StartPosition'),
                    match: this.getElements('./Match', snippetSubitem).map(x => x.textContent),
                  };

                  return snippetData;
                })
              };

              return snippetItem;
            }),
          };

          return data;
        }),
        socialNetworkProfileData: this.getElements('./SocialNetworkProfileData', link).map(item => {
          const data: SocialNetworkProfileData = {
            profileName: item.getAttribute('ProfileName'),
            url: item.getAttribute('Url'),
            phoneNumber: item.getAttribute('PhoneNumber'),
            birthDate: item.getAttribute('BirthDate'),
            birthPlace: item.getAttribute('BirthPlace'),
            fio: this.getElements('./Fio', item).map(subitem => {
              const fio: Fio = {
                fullName: subitem.getAttribute('FullName') || '',
                lastName: subitem.getAttribute('LastName') || '',
                firstName: subitem.getAttribute('FirstName') || '',
                middleName: subitem.getAttribute('MiddleName') || '',
                current: subitem.getAttribute('Current') === 'true',
                techInfo: this.getElements('./TechInfo', subitem).map(x => {
                  const techInfo: FioTechInfo = {
                    accuracy: x.getAttribute('Accuracy') ? Number(x.getAttribute('Accuracy')) : null,
                    lastNameToken: x.getAttribute('LastNameToken') || '',
                    middleNameToken: x.getAttribute('MiddleNameToken'),
                    firstNameInitial: x.getAttribute('FirstNameInitial'),
                    middleNameInitial: x.getAttribute('MiddleNameInitial'),
                    method: x.getAttribute('Method')
                  };

                  return techInfo;
                })[0],
              };

              return fio;
            }),
            imageUrl: this.selector('./ImageUrl', XPathResult.STRING_TYPE, item).stringValue,
            imageFile: this.getElements('./ImageFile', item).map(subitem => {
              const fileData: FileType = {
                id: subitem.getAttribute('Id'),
                fileName: subitem.getAttribute('FileName'),
                filePath: subitem.getAttribute('FilePath'),
                filePathInFES: subitem.getAttribute('FilePathInFES'),
                sourceFileName: subitem.getAttribute('SourceFileName'),
              };

              return fileData;
            })[0],
          };

          return data;
        }),
        director: this.getElements('./Director', link).map(item => {
          const data: Director = {
            directorName: item.getAttribute('DirectorName'),
            directorInn: item.getAttribute('DirectorInn')
          };

          return data;
        }),
        founderOrganization: this.getElements('./FounderOrganization', link).map(item => {
          const data: FounderOrganization = {
            founderOrgName: item.getAttribute('FounderOrgName'),
            founderOrgInn: item.getAttribute('FounderOrgInn'),
            founderOrgOgrn: item.getAttribute('FounderOrgOgrn')
          };

          return data;
        }),
        founderPerson: this.getElements('./FounderPerson', link).map(item => {
          const data: FounderPerson = {
            founderPersonName: item.getAttribute('FounderPersonName'),
            founderPersonInn: item.getAttribute('FounderPersonInn')
          };

          return data;
        }),
        sharerOrganization: this.getElements('./SharerOrganization', link).map(item => {
          const data: SharerOrganization = {
            sharerOrgName: item.getAttribute('SharerOrgName'),
            sharerOrgInn: item.getAttribute('SharerOrgInn'),
            sharerOrgOgrn: item.getAttribute('SharerOrgOgrn')
          };

          return data;
        }),
        sharerPerson: this.getElements('./SharerPerson', link).map(item => {
          const data: SharerPerson = {
            sharerName: item.getAttribute('SharerName'),
            sharerInn: item.getAttribute('SharerInn')
          };

          return data;
        }),
        communicationCircleData: this.getElements('./CommunicationCircleData', link).map(item => {
          const data: CommunicationCircleData = {
            side1Role: item.getAttribute('Side1Role'),
            side2Role: item.getAttribute('Side2Role'),
          };

          return data;
        })[0],
      }
    }

    const rawFields = { ...baseProps, ...workProps, ...otherOutputProps }
    const relationMapLink = new RelationMapLink(rawFields);

    return relationMapLink;
  }

  selector = (xpath: string, resultType: number, contextNode: Document | Element = this.xmlDoc) => {
    return this.xmlDoc.evaluate(xpath, contextNode, this.nsResolver, resultType, null);
  }

  getElements = (xpath: string, contextNode: Document | Element = this.xmlDoc) => {
    const result: Element[] = [];

    const query = this.selector(xpath, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, contextNode);
    for (let i = 0; i < query.snapshotLength; i++) {
      result.push(<Element>query.snapshotItem(i));
    }

    return result;
  }
}
