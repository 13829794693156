import React from 'react';
import AppSwitch from '@components/AppSwitch/AppSwitch';
import { NodeRawFields, Fio } from '@core/models/relation-map-types';
import { Box, FormControl, FormControlLabel, MenuProps, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { EditNodeForm } from '../hooks/useNodeEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    field: {
      margin: '5px 0',
      width: '100%',
      '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: '0',
        '& .MuiTypography-root': {
          width: '175px',
        },
      },
    },
    controlField: {
      '& .MuiTypography-root': {
        width: '120px',
      },
    },
    disabledField: {
      margin: '5px 0',
      width: '100%',
    },
    row: {
      display: 'flex',
      margin: '0 -5px',
    },
    fieldsCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      margin: '0 5px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 -5px',
      width: '50%',
    },
    controls: {
      display: 'flex',
    },
    footer: {
      display: 'flex',
      marginTop: '10px',
      paddingTop: '10px',
      borderTop: `1px solid ${theme.palette.text.hint}`,
      '& button': {
        margin: '0 10px 0 0',
      },
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  style: {
    maxHeight: '400px',
  },
};

type INodePropertiesProps = {
  isCreate: boolean;
  fields: NodeRawFields | null;
  formValue: EditNodeForm;
  onChangeForm: (key: keyof EditNodeForm, value: any) => void;
  validation: Record<keyof EditNodeForm, boolean>;
};

const NodeProperties: React.FC<INodePropertiesProps> = (props) => {

  const { isCreate, formValue, onChangeForm, validation } = props;

  const classes = useStyles();

  const onChange = (key: keyof EditNodeForm) => (value: any) => {
    onChangeForm(key, value);
  };

  const onChangeSelect = (key: keyof EditNodeForm) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    onChange(key)(event.target.value as any);
  };

  const onChangeTextField = (key: keyof EditNodeForm, { target }: React.SyntheticEvent) => {
    const value = (target as HTMLInputElement).value;
    onChange(key)(value);
  };

  const onChangeLastName = ({ target }: React.SyntheticEvent) => {
    const value = (target as HTMLInputElement).value;
    onChangeFio('lastName')(value);
  };

  const onChangeFirstName = ({ target }: React.SyntheticEvent) => {
    const value = (target as HTMLInputElement).value;
    onChangeFio('firstName')(value);
  };

  const onChangeMiddleName = ({ target }: React.SyntheticEvent) => {
    const value = (target as HTMLInputElement).value;
    onChangeFio('middleName')(value);
  };

  const onChangeFio = (key: keyof Fio) => (value: any) => {
    const fio = { ...formValue.fio, [key]: value }
    onChange('fio')(fio);
  };

  const onChangeName = ({ target }: React.SyntheticEvent) => {
    const value = (target as HTMLInputElement).value;
    onChange('name')(value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.form}>
        <Box className={classes.field}>
          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel id='node-type-field'>Тип узла</InputLabel>
            <Select
              disabled={!isCreate}
              labelId='node-type-field'
              label='Тип узла'
              value={formValue.type || ''}
              error={!validation.type}
              MenuProps={menuProps}
              onChange={onChangeSelect('type')}
            >
              <MenuItem key={0} value={'NodePersonType'}>
                ФЛ
              </MenuItem>
              <MenuItem key={1} value={'NodeOrganizationType'}>
                ЮЛ
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        {formValue.type === 'NodePersonType' && (
          <>
            <Box className={classes.field}>
              <TextField
                label={'Фамилия'}
                placeholder={'Фамилия'}
                fullWidth
                size='small'
                variant='outlined'
                value={formValue.fio.lastName}
                error={!validation.fio}
                onChange={onChangeLastName}
              />
            </Box>
            <Box className={classes.field}>
              <TextField
                label={'Имя'}
                placeholder={'Имя'}
                fullWidth
                size='small'
                variant='outlined'
                value={formValue.fio.firstName}
                error={!validation.fio}
                onChange={onChangeFirstName}
              />
            </Box>
            <Box className={classes.field}>
              <TextField
                label={'Отчество'}
                placeholder={'Отчество'}
                fullWidth
                size='small'
                variant='outlined'
                value={formValue.fio.middleName}
                error={!validation.fio}
                onChange={onChangeMiddleName}
              />
            </Box>
          </>
        )}

        {formValue.type === 'NodeOrganizationType' && (
          <Box className={classes.field}>
            <TextField
              label={'Название организации'}
              placeholder={'Название организации'}
              fullWidth
              size='small'
              variant='outlined'
              value={formValue.name}
              error={!validation.name}
              onChange={(event) => onChangeTextField('name', event)}
            />
          </Box>
        )}

        {formValue.type && (
          <>
            <Box className={classes.field}>
              <TextField
                label={'Глубина поиска при расширении в СПАРК'}
                placeholder={'Глубина поиска при расширении в СПАРК'}
                fullWidth
                size='small'
                variant='outlined'
                value={formValue.linkLevel}
                error={!validation.linkLevel}
                onChange={(event) => onChangeTextField('linkLevel', event)}
              />
            </Box>
            <Box className={classes.field}>
              <FormControlLabel
                control={
                  <AppSwitch
                    value={formValue.isClusterNode}
                    onChange={(value) => onChange('isClusterNode')(value)}
                  />
                }
                label='Кластерный узел'
                labelPlacement='start'
              />
            </Box>
          </>
        )}


        {formValue.type === 'NodePersonType' && (
          <>
            <Box className={classes.field}>
              <FormControlLabel
                control={
                  <AppSwitch
                    value={formValue.isClient}
                    onChange={(value) => onChange('isClient')(value)}
                  />
                }
                label='Клиент'
                labelPlacement='start'
              />
            </Box>
            <Box className={classes.field}>
              <FormControlLabel
                control={
                  <AppSwitch
                    value={formValue.isJudge}
                    onChange={(value) => onChange('isJudge')(value)}
                  />
                }
                label='Судейское лицо'
                labelPlacement='start'
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default NodeProperties;
