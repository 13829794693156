import React from 'react';
import NumberField from '@components/NumberField';
import { LinkRawFields, LinkTypes } from '@core/models/relation-map-types';
import { Box, FormControl, FormControlLabel, MenuProps, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { EditLinkForm } from '../hooks/useLinkEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    field: {
      margin: '5px 0',
      width: '100%',
      '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: '0',
        '& .MuiTypography-root': {
          width: '175px',
        },
      },
    },
    controlField: {
      '& .MuiTypography-root': {
        width: '120px',
      },
    },
    disabledField: {
      margin: '5px 0',
      width: '100%',
    },
    row: {
      display: 'flex',
      margin: '0 -5px',
    },
    fieldsCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      margin: '0 5px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 -5px',
      width: '50%',
    },
    controls: {
      display: 'flex',
    },
    footer: {
      display: 'flex',
      marginTop: '10px',
      paddingTop: '10px',
      borderTop: `1px solid ${theme.palette.text.hint}`,
      '& button': {
        margin: '0 10px 0 0',
      },
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  style: {
    maxHeight: '400px',
  },
};

type ILinkPropertiesProps = {
  isCreate: boolean;
  fields: LinkRawFields | null;
  formValue: EditLinkForm;
  onChangeForm: (key: keyof EditLinkForm, value: any) => void;
  validation: Record<keyof EditLinkForm, boolean>;
};

const LinkProperties: React.FC<ILinkPropertiesProps> = (props) => {

  const { isCreate, formValue, onChangeForm, validation } = props;

  const classes = useStyles();

  const onChange = (key: keyof EditLinkForm) => (value: any) => {
    onChangeForm(key, value);
  };

  const onChangeSelect = (key: keyof EditLinkForm) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    onChange(key)(event.target.value as any);
  };

  const linkTypes = LinkTypes.map((item, i) => {
    return (
      <MenuItem key={i} value={item}>
      {item}
      </MenuItem>
    )
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.form}>
        <Box className={classes.field}>
          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel id='link-element-type-field'>Тип связи</InputLabel>
            <Select
              disabled={!isCreate}
              labelId='link-element-type-field'
              label='Тип связи'
              value={formValue.type || ''}
              error={!validation.type}
              MenuProps={menuProps}
              onChange={onChangeSelect('type')}
            >
              <MenuItem key={0} value={'LinkWorkType'}>
                LinkWorkType
              </MenuItem>
              <MenuItem key={1} value={'LinkOtherOutputType'}>
                LinkOtherOutputType
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        {formValue.type && (
          <Box className={classes.field}>
            <NumberField
              label={'Достоверность'}
              max={1}
              min={0}
              precision={2}
              step={0.01}
              error={!validation.linkWeight}
              value={formValue.linkWeight !== null ? formValue.linkWeight.toString() : ''}
              onChange={onChange('linkWeight')}
            />
          </Box>
        )}

        {formValue.type === 'LinkOtherOutputType' && (
          <Box className={classes.field}>
            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel id='link-kind-field'>Вид связи</InputLabel>
              <Select
                labelId='link-kind-field'
                label='Вид связи'
                value={formValue.linkType || ''}
                error={!validation.linkType}
                MenuProps={menuProps}
                onChange={onChangeSelect('linkType')}
              >
                {linkTypes}
              </Select>
            </FormControl>
          </Box>
        )}
        
      </Box>
    </Box>
  );
};

export default LinkProperties;
