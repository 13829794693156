import { LayoutXf } from './layout-xf';

export class GraphLayout {
  settings = {
    BackColor: '#FFFFFF',
    FontColor: '#000000',
    FontFace: 'Arial',
    FontSize: 7,
    Id: null,
    LabelConfig: [{ ObjectType: 3, Fields: [3701, 3703] }],
    LinkColor: '#583ded',
    MAXLinkWidth: 3,
    MAXNodeSize: 32,
    MINNodeSize: 16,
    NodeStrokeColor: '#0393dd',
    UserId: 1613,
  };

  nodes = [];
  links = [];

  graph;

  constructor(keepPositions) {
    this.graph = new LayoutXf();

    this.graph.create('#graph', keepPositions);
  }

  loadData(graph) {
    this.nodes = graph.Nodes;
    this.links = graph.Links;
    this.createGraph();
  }

  createGraph() {
    //рачсет динамических параметров графа
    var s0 = parseFloat(this.settings.MINNodeSize),
      ds = parseFloat(this.settings.MAXNodeSize) - s0,
      mlw = parseFloat(this.settings.MAXLinkWidth) - 1;
    for (var i = 0, nc = this.nodes.length; i < nc; i++) {
      var node = this.nodes[i];
      node.iconHeight = node.iconWidth = s0 + Math.floor(node.Size * ds);
    }
    for (var i = 0, lc = this.links.length; i < lc; i++) {
      var link = this.links[i];
      link.color = link.Color ? link.Color : '#000';
      if (link.Width) {
        link.width = link.Width;
      } else {
        link.width = 1 + link.Size * mlw;
      }
    }

    this.graph.setNodes(this.nodes, this.links, true);
    this.graph.clearNodeSelection();
    this.graph.fitZoom();
    this.graph.update();
  }
}
