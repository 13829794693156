import AppTooltip from '@components/AppTooltip';
import { ZoomNetwork } from '@core/types/zoom-network';
import { createStyles, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    zoom: {
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      width: '100px',
    },
  }),
);

interface IZoomMapSettingsProps {
  aspectRatioText?: string;
  disabled: boolean;
  onChange: (type: ZoomNetwork) => void;
}

const ZoomMapSettings = (props: IZoomMapSettingsProps) => {
  const classes = useStyles();

  const {
    onChange,
    aspectRatioText = 'Вписать узлы в экран',
    disabled
  } = props;

  return (
    <div className={classes.root}>
      <AppTooltip title={aspectRatioText}>
        <IconButton
          disabled={disabled}
          size="small"
          onClick={() => onChange('init')}
        >
          <AspectRatioIcon fontSize="small" />
        </IconButton>
      </AppTooltip>

      <div className={classes.zoom}>
        <AppTooltip title="Уменьшить масштаб">
          <IconButton
            disabled={disabled}
            size="small"
            onClick={() => onChange('min')}
          >
            <ZoomOutIcon fontSize="small" />
          </IconButton>
        </AppTooltip>

        <AppTooltip title="Увеличить масштаб">
          <IconButton
            disabled={disabled}
            size="small"
            onClick={() => onChange('max')}
          >
            <ZoomInIcon fontSize="small" />
          </IconButton>
        </AppTooltip>
      </div>
    </div>
  );
};

export default ZoomMapSettings;
