export function Renderer(graph) {
  this.graph = graph;
}

Renderer.prototype.selector = 'g.ak_node';

//создает svg элементы узла
Renderer.prototype.create = function (sel) {
  var graph = this.graph;
  var timeout = null;

  //группа, в которой находятся элементы узла
  var node = sel
    .append('svg:g')
    .attr('class', 'ak_node')
    .on('click.graph', function (d) {
      clearTimeout(timeout);

      const pointerEvent = d3.event;
      timeout = setTimeout(function () {
        graph.event.nodeClick({ d: d, el: this, ev: pointerEvent });
      }, 300);
    })
    .on('dblclick.graph', function (d) {
      clearTimeout(timeout);

      graph.event.nodeDblclick({ d: d, el: this }); 
    });
  //иконка узла
  //:todo:
  node.append('svg:use').attr('class', 'ak_icon');

  //картинка названия
  node.append('svg:use').attr('class', 'ak_labelUse');

  //подпись узла
  node
    .append('svg:text')
    .style('display', 'none')
    .style('fill', '#000000')
    .attr('class', 'ak_label text')
    .attr('text-anchor', 'middle')
    .attr('dy', '1em');

  //всплывающая подсказка у узла
  node.append('title');
  //кружочек выделения.
  node.append('svg:circle').attr('class', 'ak_node_selection').attr('r', '15');
  node.append('svg:circle').attr('class', 'ak_synset');

  this.update(node);
  return node;
};

Renderer.prototype.getStroke = function (d) {
  var iconWidth = this.graph.baseIconWidth / 2;

  return iconWidth * d.scale + 3;
};

//устанавливает свойства узла
Renderer.prototype.update = function (sel) {
  var graph = this.graph;

  sel
    .attr('id', function (d) {
      d.view = this;
      return d.id ? 'n' + d.id : 'n' + d.index;
    })
    .attr('nodeId', (d) => d.ID)
    .classed('fixed', function (d) {
      return graph.isFixed(d);
    });

  var iconWidth = graph.baseIconWidth / 2;

  sel
    .select('use.ak_icon')
    .attr('nodeId', (d) => d.ID)
    .attr('transform', function (d) {
      return d.scale == 1 ? null : 'scale(' + d.scale + ')';
    })
    .attr('xlink:href', function (d) {
      return d.iconId ? d.iconId : !d.icon ? null : (d.iconId = '#' + graph.registerIcon(d.icon));
      // d.iconId = '#' + graph.registerIcon(d.icon);
    });

  sel
    .select('text.ak_label.text')
    .style('display', function () {
      return 'block';
    })
    .attr('text', (d) => d.Name)
    .attr('y', function (d) {
      return iconWidth * d.scale * 1.5 + 'px';
    })
    .text(function (d) {
      return d.Name;
    }); //хак с пробелами для хрома, у которого появляются артефакты при перетаскивании

  sel.select('title').text(function (d) {
    return d.title;
  });

  // sel.select('.ak_node_selection').attr('r', this.getStroke.bind(this));

  sel.select('.ak_synset').attr('r', (d) => {
    return d.InSenset === true ? this.getStroke(d) : 0;
  });

  this.updateSelection(sel);
  this.position(sel);
};

Renderer.prototype.updateSelection = function (nodes) {
  nodes.classed('selected', function (d) {
    return d.selected;
  });
};

Renderer.prototype.updateFixed = function (nodes, fixed) {
  nodes.classed('fixed', !!fixed);
};

//устанавливает позицию узла
Renderer.prototype.position = function (nodes) {
  var graph = this.graph;
  graph.scaleMarkers();
  if (graph.isSkeleton()) {
    nodes.attr('transform', function (d) {
      return 'translate(' + d.xd + ',' + d.yd + ')';
    });
  } else {
    nodes.attr('transform', function (d) {
      return 'translate(' + d.xd + ',' + d.yd + ') scale(' + graph.scaleInv + ')';
    });
  }
};

Renderer.prototype.toggleLabel = function (nodes, show) {
  nodes.select('text.ak_label').style('display', () => (show ? null : 'none'));
};
