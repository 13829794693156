import React from 'react';
import { Tooltip } from '@material-ui/core';

interface IAppTooltipProps {
  title: React.ReactElement | string;
  children: React.ReactElement;
  open?: boolean;
}

const AppTooltip = (props: IAppTooltipProps) => {

  const title = props.title && props.title.toString().length > 0 ? <span>{props.title}</span> : '';

  return props.open === undefined ? (
    <Tooltip title={title}>{props.children}</Tooltip>
  ) : (
    <Tooltip title={title} open={props.open}>
      {props.children}
    </Tooltip>
  );
};

export default AppTooltip;
