import { ColDef } from 'ag-grid-community';

const settings: any = {
  defFirstColumn: {
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  rowSelection: 'multiple',
};

const cellStyleCommon = {
  'white-space': 'normal',
  'line-height': '18px',
  'display': 'flex',
  'align-items': 'flex-start',
  'word-break': 'break-all',
};

const colDef: ColDef = {
  autoHeight: true,
  sortable: true,
  unSortIcon: true,
  resizable: true,
  cellStyle: cellStyleCommon,
};

settings.defaultColDef = colDef;

export default settings;
