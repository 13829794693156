import React, { Component, SyntheticEvent } from 'react';
import { IFilterParams, RowNode } from 'ag-grid-community';
import { TextField } from '@material-ui/core';
import './styles.scss';

interface ISmartTableFilterState {
  text: string;
}

export default class SmartTableFilter extends Component<IFilterParams, ISmartTableFilterState> {
  state: ISmartTableFilterState;

  valueGetter: (rowNode: RowNode) => any;

  placeholder: string | undefined;

  inputRef: HTMLInputElement | null = null;

  readonly filterKey: string | null = null;

  constructor(props: IFilterParams) {
    super(props);

    if (props.colDef.filterParams && props.colDef.filterParams['key']) {
      this.filterKey = props.colDef.filterParams['key'];
    }

    this.state = {
      text: '',
    };

    this.valueGetter = this.props.valueGetter;

    this.onChange = this.onChange.bind(this);

    this.placeholder = props.colDef.headerName;
  }

  isFilterActive() {
    return this.state.text != null && this.state.text !== '';
  }

  doesFilterPass(params: { node: any }) {

    if (!this.valueGetter(params.node)) {
      return false;
    }

    const value = typeof this.valueGetter(params.node) === 'object' && this.filterKey
      ? this.valueGetter(params.node)[this.filterKey]
      : this.valueGetter(params.node);

    return this.state.text
      .toLowerCase()
      .split(' ')
      .every(
        (filterWord) => (value || '').toString().toLowerCase().indexOf(filterWord) >= 0,
      );
  }

  getModel() {
    return { value: this.state.text };
  }

  setModel(model: { value: string }) {
    this.setState({
      text: model ? model.value : '',
    });
  }

  onChange(event: SyntheticEvent) {
    const { value } = event.target as HTMLInputElement;

    if (this.state.text !== value) {
      this.setState({ text: value }, this.props.filterChangedCallback);
    }
  }

  initRef = (ref: HTMLInputElement) => {
    this.inputRef = ref;
    if (ref) {
      setTimeout(() => ref.focus(), 11);
    }
  };

  render() {
    return (
      <div className="s-filter">
        <TextField
          label={this.placeholder}
          variant="outlined"
          size="small"
          value={this.state.text}
          onChange={this.onChange}
          inputRef={this.initRef}
        />
      </div>
    );
  }
}
