import React from 'react';
import { SvgIcon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  small: {
    height: '14px!important',
    width: '14px!important',
  },
  default: {
    height: '16px!important',
    width: '16px!important',
  },
});

interface IFunnelIconProps {
  small?: boolean;
}

const FunnelIcon = (props: IFunnelIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 477.875 477.875" className={props.small ? classes.small : classes.default}>
      <path
        d="M460.804,0H17.071C7.645,0,0.004,7.641,0.004,17.067V102.4c-0.004,
      4.842,2.05,9.458,5.649,12.698l165.018,148.514V460.8
        c-0.004,9.426,7.633,17.07,17.059,17.075c2.651,0.001,5.266-0.615,7.637-1.8l102.4-51.2c5.786-2.891,
        9.441-8.806,9.438-15.275
        V263.612l165.018-148.48c3.608-3.247,5.662-7.878,5.649-12.732V17.067C477.871,7.641,470.23,0,460.804,0z"
      />
    </SvgIcon>
  );
};

export default FunnelIcon;
