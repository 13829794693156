import { ISmartTableDto } from '@core/models/smart-table.interface';

export const EDGES_TABLE: ISmartTableDto[] = [
  {
    colId: 'checkbox',
    field: 'checkbox',
    index: 0,
    hide: false,
  },
  {
    colId: 'name',
    field: 'name',
    index: 1,
    hide: false,
  },
];
