//@ts-nocheck
import * as d3 from 'd3';
import { Renderer as nodeRenderer } from './node-renderer';
import { Renderer as linkRenderer } from './link-renderer';
import { DocumentEventsGraphTypes, DocumentEventsGraph } from '@pages/RelationMap/events';
import VisualizationOptions from '@pages/RelationMap/visualization';

export class LayoutXf {
  event = d3.dispatch(
    'nodeClick', //клик по узлу графа
    'nodeDblclick', // двойной клик по узлу графа
    'linkClick', //клик по ребру графа
    'linkDblClick', //двойной клик клик по ребру графа
    'dragEnd', //узел графа перетащили в новое место
    'selectionChanged', //изменился набор выделенных узлов
    'fixedChanged', //изменился набор фиксированных узлов
    'dataBound', //в граф загружены новые данные
    'animationStart', // начало анимации раскладки графа
    'animationEnd', // конец анимации раскладки графа
    'scaleChanged',
    'layoutComplete',
  );
  nodes = []; //массив данных для нодов графа
  links = []; //массив данных для ребер графа
  svgDefs; //секция определений маркеров
  bgRect; //фоновый прямоугольник, отвечает за обработку перетаскивания и выделения рамкой
  selRect; //рамка выделения
  scalePane; //панель масштабирования/перетаскивания
  canvas; //панель, содержащая элементы графа
  nodesBlock;
  linksBlock; //группы для отрисовки узлов и связей графа
  nodeElems = d3.selectAll([]); //список svg элементов для нодов
  linkElems = d3.selectAll([]); //Список svg элементов для связей
  nodeSelection = []; //выделенные узлы графа
  linkSelection = null; //выделенные ребра графа
  safeSelection = true;
  selectionMode = false;
  zoomEnabled = false; //флаг включения перетаскивания
  drag = null; //менеджер перетаскивания узлов
  dragInfo = {}; //данные для массового перетаскивания
  noAnimation = false;
  svgDefCounter = 0;
  _iconUrls = {}; // кэш адресов иконок для убирания дублей
  created = false; //флаг инициализации
  t;

  baseIconWidth = 70; // стандартный размер иконки
  baseLinkDistance = 40; // стандартная длина связи
  matrix = {}; // матица смежности
  nodeRender; // отрисовщик узлов
  linkRender; // отрисовщик связей
  fixedSelection = true;
  fixAllOnDrag = true;
  skeletonScale = 0.6;
  copyNodePropsOnSet = true; //перезаписать свойства узалов из модели при установке данных
  redrawTicks = 1;

  shallowTick = 0;

  options = {};

  markers = {};

  scaleInv;

  tScale;

  zoom;

  sc;

  hiddenNodesIds = [];
  hiddenLinksIds = [];

  currentScale = 0;

  TBScales = [0.25, 0.5, 0.75, 1, 1.5, 2, 3, 5, 10];

  singleSelect = false;

  gravityConfig = {
    factor: 0.03,
    mass: function(n) {
      return 1 + n.scale;
    },
    nodeLayout: {
      factor: 1,
      yr: 20, //удерживать расстояние по х между узлами
      xMin: 30,
      xMax: 600, //расстояние включения
      yMax: 20,
    },
  };

  tempLinkSelect;

  selectNodesHandler;
  hiddenNodesHandler;
  selectLinksHandler;
  hiddenLinksHandler;
  changeOptionsHandler;
  zoomChangedHandler;
  requestPositionsHandler;

  constructor() {
    this.options = VisualizationOptions;

    this.nodeRender = new nodeRenderer(this);
    this.linkRender = new linkRenderer(this);

    this.selectNodesHandler = this.selectNodes.bind(this);
    this.hiddenNodesHandler = this.handleHiddenNodes.bind(this);
    this.selectLinksHandler = this.selectLinks.bind(this);
    this.hiddenLinksHandler = this.handleHiddenLinks.bind(this);
    this.changeOptionsHandler = this.changeOptions.bind(this);
    this.zoomChangedHandler = this.handleZoomChanged.bind(this);
    this.requestPositionsHandler = this.handleRequestPositions.bind(this);

    this.addListeners();
  }

  handleNodesChanged = (event) => {
    const nodes = event.detail.data;

    if (nodes.length === 0) {
      this.clearNodeSelection();
    } else {
      console.log(nodes);
    }
  };

  handleZoomChanged = (event) => {
    const { detail } = event;

    const value = detail.data.value;

    if (value === 'init') {
      this.fitZoom();
    } else {
      this.setTBScale(this.scale(), value === 'max' ? +1 : -1);
    }
  };

  addListeners = () => {
    document.addEventListener(DocumentEventsGraphTypes.SELECTED_NODES, this.selectNodesHandler);
    document.addEventListener(DocumentEventsGraphTypes.HIDDEN_NODES, this.hiddenNodesHandler);
    document.addEventListener(DocumentEventsGraphTypes.SELECTED_LINKS, this.selectLinksHandler);
    document.addEventListener(DocumentEventsGraphTypes.HIDDEN_LINKS, this.hiddenLinksHandler);
    document.addEventListener(DocumentEventsGraphTypes.CHANGE_OPTIONS, this.changeOptionsHandler);
    document.addEventListener(DocumentEventsGraphTypes.ZOOM_CHANGED, this.zoomChangedHandler);
    document.addEventListener(DocumentEventsGraphTypes.SAVE_FILE, this.requestPositionsHandler);
    document.addEventListener(DocumentEventsGraphTypes.EDIT_NODE, this.requestPositionsHandler);
    document.addEventListener(DocumentEventsGraphTypes.EDIT_LINK, this.requestPositionsHandler);
  };

  removeListeners = () => {    
    document.removeEventListener(DocumentEventsGraphTypes.SELECTED_NODES, this.selectNodesHandler);
    document.removeEventListener(DocumentEventsGraphTypes.HIDDEN_NODES, this.hiddenNodesHandler);
    document.removeEventListener(DocumentEventsGraphTypes.SELECTED_LINKS, this.selectLinksHandler);
    document.removeEventListener(DocumentEventsGraphTypes.HIDDEN_LINKS, this.hiddenLinksHandler);
    document.removeEventListener(DocumentEventsGraphTypes.CHANGE_OPTIONS, this.changeOptionsHandler);
    document.removeEventListener(DocumentEventsGraphTypes.ZOOM_CHANGED, this.zoomChangedHandler);
    document.removeEventListener(DocumentEventsGraphTypes.SAVE_FILE, this.requestPositionsHandler);
    document.removeEventListener(DocumentEventsGraphTypes.EDIT_NODE, this.requestPositionsHandler);
    document.removeEventListener(DocumentEventsGraphTypes.EDIT_LINK, this.requestPositionsHandler);
  };

  selectNodes = (event) => {
    const nodes = event.detail.data.sort((a, b) => a > b ? 1 : -1);

    const isExist = (id) => nodes.some(nodeId => nodeId === id);

    const selected = [];

    this.nodes = this.nodes.map((node) => {

      const view = d3.select(node.view);

      this.clearNodeSelection(view);

      if (isExist(node.ID)) {
        this.setNodeSelection(view);
        selected.push(node);
      } else {
        this.clearNodeSelection(view);
      }

      node.selected = !node.selected;

      this.event.selectionChanged({ type: 'selectionChanged' });

      return node;
    });

    this.nodeSelection = selected;
  };

  selectLinks = (event) => {
    const linkId = event.detail.data[0];

    const isExist = (id) => linkId === id;

    const selected = [];

    this.links = this.links.map((link) => {

      const view = d3.select(link.view);

      this.clearLinkSelection(view);

      if (isExist(link.ID)) {
        const element = view[0][0];
        this.tempLinkSelect = {
          link: element,
          color: element.querySelector('.ak_link_path').getAttribute('stroke')
        };
        this.setLinkSelection(view);
        selected.push(link);
      } else {
        this.clearLinkSelection(view);
      }

      link.selected = !link.selected;

      this.event.selectionChanged({ type: 'selectionChanged' });

      return link;
    });

    this.linkSelection = selected;
  };

  getConnectedLinksIds = (nodeId) => {
    return this.links
      .filter((link) => {
        return link.SourceThemeID === nodeId || link.DestThemeID === nodeId;
      })
      .map((link) => link.ID);
  };

  getConnectedNodesIds = (linkId) => {
    const link = this.links.find((link) => link.ID === linkId);

    if (!link) {
      return [];
    }

    return [link.SourceThemeID, link.DestThemeID];
  };

  getNodeView = (nodeId) => {
    let view;

    this.nodeElems.each((ref) => {
      if (ref.ID === nodeId) {
        view = ref.view;
      }
    });

    return view;
  };

  handleHiddenNodes = (event) => {
    const { nodes: nodesToHide } = event.detail.data;
    event.stopImmediatePropagation();

    if (!nodesToHide) {
      return;
    }

    const nodesToShow = this.hiddenNodesIds.filter((node) => !nodesToHide.includes(node));

    nodesToHide.filter((node) => !this.hiddenNodesIds.includes(node)).forEach((node) => {
      const ids = this.getConnectedLinksIds(node);

      this.nodeElems.each((ref) => {
        if (node === ref.ID) {
          ref.view.style.opacity = 0;
          ref.view.style.pointerEvents = 'none';
        }
      });

      new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.HIDDEN_LINKS_RELATED, {
        links: [...this.hiddenLinksIds, ...ids],
      });
    });

    nodesToShow.forEach((node) => {
      const ids = this.getConnectedLinksIds(node);

      this.nodeElems.each((ref) => {
        if (node === ref.ID) {
          ref.view.style.opacity = 1;
          ref.view.style.pointerEvents = 'auto';
        }
      });

      const linkIdsToShow = [];

      this.linkElems.each((ref) => {
        const existLink = ids.includes(ref.ID);

        if (existLink === false) {
          return;
        }

        const sourceView = ref.source.view;
        const targetView = ref.target.view;

        const isVisible = (view) => {
          const opacity = view.style.opacity;

          return opacity === '1' || opacity.length === 0;
        };

        if (isVisible(sourceView) && isVisible(targetView)) {
          linkIdsToShow.push(ref.ID);
        }
      });

      new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.HIDDEN_LINKS_RELATED, {
        links: this.hiddenLinksIds.filter((id) => !linkIdsToShow.includes(id)),
      });
    });

    this.hiddenNodesIds = nodesToHide;
  };

  handleHiddenLinks = (event) => {
    const { links: linksToHide } = event.detail.data;

    if (!linksToHide) {
      return;
    }

    const linksToShow = this.hiddenLinksIds.filter((link) => !linksToHide.includes(link));

    linksToHide.forEach((link) => {
      this.linkElems.each((ref) => {
        if (link === ref.ID) {
          ref.view.style.opacity = 0;
          ref.view.style.pointerEvents = 'none';
        }
      });
    });

    linksToShow.forEach((link) => {
      this.linkElems.each((ref) => {
        if (link === ref.ID) {
          ref.view.style.opacity = 1;
          ref.view.style.pointerEvents = 'auto';
        }
      });
    });

    this.hiddenLinksIds = linksToHide;
  };

  changeOptions = (event) => {
    this.options = { ...event.detail.data.options };

    const {
      bgColor,
      fontBold,
      fontFamily,
      fontSize,
      maxNameSize,
      selectedNodeColor,
      selectedLinkColor
    } = this.options;

    const titles = document.querySelectorAll('.ak_label');

    [...titles].forEach((title) => {
      title.style.fontSize = `${fontSize}px`;
      title.style.fontFamily = fontFamily;
      title.style.fontWeight = fontBold ? '600' : '300';

      const textOrigin = title.getAttribute('text');

      let text = textOrigin.slice(0, maxNameSize);

      if (textOrigin.length > text.length) {
        text += '...';
      }

      title.innerHTML = text;
    });

    const svg = document.querySelector('.ak_background');
    svg.style.fill = bgColor;

    const selected = document.querySelectorAll('.selected');
    [...selected].forEach((element) => {
      const node = element.querySelector('.ak_node_selection');
      node.style.stroke = selectedNodeColor;
      const link = element.querySelector('.ak_link_path');
      link.style.stroke = selectedLinkColor;
    });
  };

  handleRequestPositions = (event) => {
    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.RESPONSE_POSITIONS, {
      nodes: this.nodes,
      requestData: {
        key: event.type,
        value: event.detail.data
      }
    });
  };

  setTBScale(scale, inc) {
    inc = inc ? inc : 0;
    var k = 0;
    for (var i = this.TBScales.length - 1; i >= 0; i--) {
      if (scale > this.TBScales[i] - 0.0001) {
        k = Math.min(this.TBScales.length - 1, Math.max(0, i + inc));
        scale = this.TBScales[k];
        break;
      }
    }
    this.scale(scale, inc);
  }

  getNodeElems() {
    return this.nodeElems;
  }

  //возврат списка SVG элементов ребер графа
  getLinkElems() {
    return this.linkElems;
  }

  registerIcon(url) {
    if (this._iconUrls[url]) return this._iconUrls[url];

    var w = this.baseIconWidth;
    var w2 = w / 2;
    var id = 'd' + this.svgDefCounter++;
    this.svgDefs
      .append('svg:image')
      .attr('xlink:href', url)
      .attr('id', id)
      .attr('x', -w2)
      .attr('y', -w2)
      .attr('width', w)
      .attr('height', w)
      .attr('clip-path', 'url(#iconClip)');

    //svgDefs.append("svg:circle").attr("id", id).attr("r", graph.baseIconWidth / 2).attr("fill", "#ff8");

    if (url.length < 250) this._iconUrls[url] = id;
    return id;
  }

  addStyle() {
    return this.svgDefs.append('style').attr('type', 'text/css');
  }

  create(selector, keepPositions) {
    if (this.created) return;
    this.created = true;

    const width = '100%';
    const height = '100%';

    const svgDom = document.querySelector(`${selector} svg`);

    if (svgDom) {
      svgDom.remove();
    }

    var svg = d3
      .select(selector)
      .append('svg:svg')
      .style('fill', '#EEE')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('width', width)
      .attr('height', height);

    this.svgDefs = svg.append('svg:defs');

    var f = this.svgDefs.append('filter').attr('id', 'outline').attr('height', '130%').attr('width', '130%');

    f.append('feGaussianBlur').attr('stdDeviation', '3').attr('result', 'glow');

    f.append('feColorMatrix')
      .attr('type', 'matrix')
      .attr('in', 'glow')
      .attr('result', 'glow1')
      .attr('values', '0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0');

    var mask = this.svgDefs
      .append('svg:clipPath')
      .attr('id', 'iconClip')
      .append('svg:circle')
      .attr('r', this.baseIconWidth / 2);

    var m = f.append('feMerge');
    m.append('feMergeNode').attr('in', 'glow1');
    m.append('feMergeNode').attr('in', 'glow1');
    //m.append('feMergeNode').attr("in", "SourceGraphic");

    const self = this;

    //фон
    this.bgRect = svg
      .append('rect')
      .classed('ak_background', true)
      .attr('width', width)
      .attr('height', height)
      .on('dblclick.graph', this.onBgDblClick.bind(this))
      .on('mousedown.graph', function() {
        self.onBgMouseDown.call(this, self);
      });

    if (!this.safeSelection) {
      this.bgRect.on('click.graph', function(d) {
        if (!self.canSelect()) {
          self.clearNodeSelection();
          self.event.selectionChanged({ type: 'selectionChanged' });
        }
      });
    }

    this.scalePane = svg.append('g');
    this.canvas = this.scalePane.append('g');
    this.linksBlock = this.canvas.append('g');
    this.nodesBlock = this.canvas.append('g');
    //рамка выделения
    this.selRect = svg
      .append('rect')
      .classed('ak_selection_box', true)
      .attr('width', 0)
      .attr('height', 0)
      .style('display', 'none');
    this.svgSelector = svg;
    this.bgRectSelector = this.bgRect;
    this.canvasSelector = this.canvas;

    var box = this.bgRect.node().getBBox();
    this.cx = 0; // box.width / 2;
    this.cy = 0; // box.height / 2;
    this.cw = box.width / box.height;

    this.force = d3.layout
      .force()
      .nodes(this.nodes)
      .links(this.links)
      .charge(function(d) {
        return -(150 + d.scale * 200 + (d.highlight || d.showText ? 100 : 0));
      })
      .gravity(0)
      .size([500, 500])
      .linkDistance(this.calcLinkDistance.bind(this))
      .linkStrength(0.2)
      .on('tick', this.forceTick.bind(this, keepPositions))
      .on('start', this.animationStart.bind(this))
      .on('end', this.animationEnd.bind(this));

    this.drag = this.force.drag();

    this.drag.origin(function(d) {
      return { x: /* graph.cx +  */ d.xd, y: /* graph.cy + */ d.yd };
    });

    this.zoom = d3.behavior.zoom().scaleExtent([0.25, 10]).on('zoom', this.transformEvent.bind(this));
    this.sc = this.scaleInv = this.scale0 = 1;
    //this.event.on('nodeClick._graph', this.onNodeClick.bind(this));
    this.event.on('nodeClick._graph', this.clickNode.bind(this));
    this.event.on('nodeDblclick._graph', this.doubleClickNode.bind(this));
    this.event.on('linkClick._graph', this.clickLink.bind(this));
    this.event.on('linkDblClick._graph', this.doubleClickLink.bind(this));
    this.setZoom(true);
    this.initDrag();
  }

  calcLinkDistance(l) {
    return this.baseIconWidth * l.source.scale + this.baseIconWidth * l.target.scale + this.baseLinkDistance; // / Math.sqrt(Math.max(0.25, l.width)) * l.source.scale * l.target.scale;
  }

  getNodeById(id) {
    var m = this.matrix[id];
    if (!m) {
      console.error('узел не найден: ' + id);
      return null;
    }
    return m._node;
  }

  recalcNodeSelection() {
    this.nodeSelection = [];
    for (var i = 0; i < this.nodes.length; i++) {
      if (this.nodes[i].selected) this.nodeSelection.push(this.nodes[i]);
    }
  }

  inverseNodeSelection() {
    const selected = [];

    this.nodes = this.nodes.map((node) => {
      const view = d3.select(node.view);

      if (node.selected) {
        this.clearNodeSelection(view);
      } else {
        this.setNodeSelection(view);
        selected.push(node);
      }

      node.selected = !node.selected;

      this.event.selectionChanged({ type: 'selectionChanged' });

      return node;
    });

    this.nodeSelection = selected;

    this.notifyComponentSelectedChanged(this.nodeSelection.map((item) => item.ID));
  }

  animationStart() {
    if (this.noAnimation) return;
    this.event.animationStart();
  }

  animationEnd() {
    if (this.noAnimation) return;
    this.event.animationEnd();
    if (this.shallowTick > 0) {
      this.redraw();
      this.shallowTick = 0;
    }
  }

  add(n, l, doLayout) {
    if (!(n && n.length) && !(l && l.length)) return;
    var matrix = this.matrix;
    var i;
    if (n && n.length) {
      for (i = 0; i < n.length; i++) {
        let o = n[i];
        if (!o.id) {
          console.error('узел без идентификатора: ' + JSON.stringify(o));
          continue;
        }
        if (typeof o.name == 'undefined') o.name = '';
        if (typeof o.title == 'undefined') o.title = o.name;
        if (typeof o.scale == 'undefined') {
          o.scale = typeof o.iconWidth == 'number' ? o.iconWidth / this.baseIconWidth : 1;
        }
        o.weight = 0;
        o.index = this.nodes.length;
        this.nodes.push(o);
        matrix[o.id] = { _node: o };
      }
    }
    if (l && l.length) {
      for (i = 0; i < l.length; i++) {
        let o = l[i];
        //замена индексов нодов на ссылки на ноды
        if (o.FromId) o.source = this.getNodeById(o.FromId);
        else if (typeof o.source == 'number') o.source = this.nodes[o.source];
        if (o.ToId) o.target = this.getNodeById(o.ToId);
        else if (typeof o.target == 'number') o.target = this.nodes[o.target];
        if (typeof o.type == 'undefined') o.type = 1;
        if (typeof o.title == 'undefined') o.title = '';
        if (typeof o.width == 'undefined') o.width = 1;

        //пропуск битых ссылок
        var m1, m2;
        if (
          typeof o.source != 'object' ||
          !(m1 = matrix[o.source.id]) ||
          typeof o.target != 'object' ||
          !(m2 = matrix[o.target.id])
        )
          continue;

        var t1 = m1[o.target.id];
        if (!t1) m1[o.target.id] = t1 = [];
        t1.push(o);

        if (o.target.id != o.source.id) {
          var t2 = m2[o.source.id];
          if (!t2) m2[o.source.id] = t2 = [];
          t2.push(o);
        }

        var n2 = Math.floor(t1.length / 2);
        o.linkOffset = (t1.length % 2 == 0) ^ (o.target.id > o.source.id) ? n2 : -n2;
        ++o.source.weight;
        ++o.target.weight;
        o.index = this.links.length;
        this.links.push(o);
      }
    }
    var box = this.bgRect.node().getBBox(),
      w2 = box.width / 2,
      h2 = box.height / 2;
    if (n && n.length) {
      //разместить узлы на карте
      for (i = 0; i < n.length; i++) {
        var o = n[i];
        if (isNaN(o.x)) o.x = o.Position ? o.Position.x : this.getNodePosition('x', w2, o);
        if (isNaN(o.y)) o.y = o.Position ? o.Position.y : this.getNodePosition('y', h2, o);
        o.px = o.x;
        o.py = o.y;
        var al = this.getAl(o.x, o.y);
        o.xd = o.Position ? o.Position.xd : o.x * al.x;
        o.yd = o.Position ? o.Position.yd : o.y * al.y;
      }
    }
    this.force.start();
    if (doLayout) {
      this.doLayout(25);
      this.force.alpha(0.5);
      this.doLayout(75);
      this.event.layoutComplete({ type: 'layoutComplete' });
    }
    if (!this.nodeElems || (n && n.length)) this.bindNodes();
    if (!this.linkElems || (l && l.length)) this.bindLinks();
    this.event.dataBound({ type: 'dataBound' });
  }

  doLayout(ticks) {
    this.noAnimation = true;
    for (var i = 0; i < ticks; ++i) this.force.tick();
    this.noAnimation = false;
  }

  remove(n, l, noAnimation) {
    if (!(n && n.length) && !(l && l.length)) return;
    var matrix = this.matrix;
    var i, j;
    var linkRebind = false;
    if (n && n.length) {
      for (i = 0; i < n.length; i++) {
        var o = n[i];
        var id, id2, m;
        if (typeof o.index != 'number' || !(id = o.id) || !(m = matrix[id])) continue;
        delete m._node;
        for (id2 in m) {
          var mt = matrix[id2];
          if (m[id2] && m[id2].length) l.push.apply(l, m[id2]); //добавить ссылки на удаление
          delete mt[id]; //удалить кросс-ссылки
          delete m[id2];
          linkRebind = true;
        }
        delete matrix[id];
        //удаление узла из массива
        if (this.nodes[o.index] == o) {
          var t = this.nodes.pop();
          if (o.index < this.nodes.length) {
            t.index = o.index;
            this.nodes[o.index] = t;
          }
          delete o.index;
          delete o.view;
        } else {
          console.error('Индекс узла не соответствует объекту в массиве');
        }
      }
    }
    if (l && l.length) {
      for (i = 0; i < l.length; i++) {
        var o = l[i];
        if (typeof o.index != 'number') continue;
        //удаление связи из массива
        if (this.links[o.index] == o) {
          var t = this.links.pop();
          if (o.index < this.links.length) {
            t.index = o.index;
            this.links[t.index] = t;
          }
          delete o.index;
          o.source.weight--;
          o.target.weight--;
        } else {
          console.error('Индекс связи не соответствует объекту в массиве');
        }
        //пропуск битых ссылок
        var m1, m2;
        if (
          typeof o.source != 'object' ||
          !(m1 = matrix[o.source.id]) ||
          typeof o.target != 'object' ||
          !(m2 = matrix[o.target.id])
        )
          continue;
        var t1 = this.removeLinkFomMatrix(m1, o.target.id, o);
        var t2 = this.removeLinkFomMatrix(m2, o.source.id, o);
        linkRebind = linkRebind || t1 || t2;
      }
    }
    if (!noAnimation) {
      this.force.start();
      this.event.dataBound({ type: 'dataBound' });
    }
    if (n && n.length) this.bindNodes();
    if (linkRebind) this.bindLinks();
  }

  removeLinkFomMatrix(m1, id, o) {
    var linkRebind = false;
    var t1 = m1[id];
    if (t1) {
      for (var j = 0; j < t1.length; j++) {
        if (t1[j] == o) {
          t1.splice(j, 1);
          j--;
          linkRebind = true;
        }
      }
      if (t1.length == 0) {
        delete m1[id];
      } else if (linkRebind) {
        //перерасчитать дуги
        for (var j = 0; j < t1.length; j++) {
          var l = t1[j],
            k = j + 1,
            n2 = Math.floor(k / 2);
          l.linkOffset = (k % 2 == 0) ^ (l.target.id > l.source.id) ? n2 : -n2;
        }
      }
    }
    return linkRebind;
  }

  compareLinks(l1, l2) {
    return l1 == l2;
  }

  setNodes(ns, ls, doLayout) {
    if (this.nodes.length == 0 && this.links.length == 0) {
      this.add(ns, ls, doLayout);
      return;
    }
    var hash = {};
    var matrix = this.matrix;
    for (var i = 0; i < this.nodes.length; i++) {
      var n = this.nodes[i];
      hash[n.Id] = n;
    }
    //узлы на добавление и удаление
    var nAdd = [],
      nRemove = [];
    for (var i = 0; i < ns.length; i++) {
      var n = ns[i];
      if (matrix[n.id]) {
        if (this.copyNodePropsOnSet) {
          //скопировать свойства узлов в текущий узел
          var m = hash[n.Id];
          if (!m) continue;
          for (var k in n) {
            m[k] = n[k];
          }
        }
        delete hash[n.id];
      } else {
        nAdd.push(n);
      }
    }
    for (var i in hash) {
      nRemove.push(matrix[i]._node);
    }
    //пометить текущие сслыки флагом на удаление
    for (var i = 0; i < this.links.length; i++) {
      this.links[i]._rf = true;
    }
    //связи на добавление
    var lAdd = [];
    for (var i = 0; i < ls.length; i++) {
      var l = ls[i];
      var m1, m2;
      var present = false;
      if ((m1 = matrix[l.FromId]) && (m2 = m1[l.ToId]) && m2.length) {
        for (var j = 0; j < m2.length; j++) {
          if (this.compareLinks(l, m2[j])) {
            present = true;
            m2[j]._rf = false;
            break;
          }
        }
      }
      if (!present) lAdd.push(l);
    }
    var lRemove = [];
    for (var i = 0; i < this.links.length; i++) {
      if (this.links[i]._rf) lRemove.push(this.links[i]);
    }

    var needAdd = nAdd.length > 0 || lAdd.length > 0;
    if (nRemove.length > 0 || lRemove.length > 0) {
      this.remove(nRemove, lRemove, needAdd);
    }
    if (needAdd) {
      this.add(nAdd, lAdd, doLayout);
      //graph.smoothAdd(nAdd, lAdd);
    }
  }

  getNodePosition(dimension, size, node) {
    var candidates = this.matrix[node.id];
    var x;
    for (var key in candidates) {
      if (key != '_node' && !isNaN((x = this.getNodeById(key)[dimension])))
        return x + Math.random() * 50 - 25;
    }
    x = node[dimension.toUpperCase()];
    if (typeof x == 'number') {
      return x * size * 2 - size;
    } else {
      return Math.random() * size * 2 - size;
    }
  }

  bindNodes() {
    var all = this.nodesBlock.selectAll(this.nodeRender.selector);
    this.nodeElems = all.data(this.nodes);
    this.nodeElems.exit().remove();
    this.nodeRender.create(this.nodeElems.enter()).call(this.drag);
    this.nodeRender.update(this.nodeElems);

    var box = this.bgRect.node().getBBox();
    this.cw = box.width / box.height;
  }

  bindLinks() {
    this.linkElems = this.linksBlock.selectAll(this.linkRender.selector).data(this.links);
    this.linkElems.exit().remove();
    this.linkRender.create(this.linkElems.enter());
    this.linkRender.update(this.linkElems);
  }

  reindex() {
    var i, nc;
    for (i = 0, nc = this.nodes.length; i < nc; ++i) {
      this.nodes[i].index = i;
    }
    for (i = 0, nc = this.links.length; i < nc; ++i) {
      this.links[i].index = i;
    }
  }

  reselect() {
    //перерасчет выделения
    this.nodeSelection = [];
    for (var i = 0; i < this.nodes.length; i++) {
      var n = this.nodes[i];
      if (n.selected) this.nodeSelection.push(n);
    }
  }

  forceTick(keepPositions, e) {
    if (keepPositions !== true) {
      this.applyGravity(e.alpha);
    }

    this.shallowTick++;
    if (this.shallowTick < this.redrawTicks) {
      this.force.tick();
    } else {
      this.redraw();
      this.shallowTick = 0;
    }
  }

  redraw(e) {
    if (this.noAnimation) return;

    this.nodeRender.position(this.nodeElems);
    this.linkRender.position(this.linkElems);
  }

  getAl(x, y) {
    var al = Math.sqrt(x * x + y * y) / Math.max(Math.abs(x), Math.abs(y));
    return {
      x: al * this.cw,
      y: al,
    };
  }

  applyGravity(alpha) {
    var gc = this.gravityConfig;
    var nl = gc.nodeLayout;
    var gravity = gc.factor * alpha;
    var allFixed = true;
    for (var i = 0, nc = this.nodes.length; i < nc; ++i) {
      var n = this.nodes[i];
      if (n.fixed) continue;
      allFixed = false;
      var g2 = 1 - gravity * gc.mass(n),
        x = n.x,
        y = n.y,
        cx2 = x * g2,
        cy2 = y * g2;
      //if (!isFinite(cx2) || !isFinite(cy2)) {
      //	debugger;
      //}
      n.x = cx2;
      n.y = cy2;
      var al = this.getAl(x, y);
      n.xd = cx2 * al.x;
      n.yd = cy2 * al.y;

      if (nl) {
        //разложить рядомстоящие
        for (var j = i + 1; j < nc; ++j) {
          var m = this.nodes[j];
          var adx = Math.abs(m.x - n.x),
            dy = m.y - n.y,
            ady = Math.abs(dy);
          if (adx > nl.xMax || ady > nl.yMax) continue;
          var sign = dy < 0 ? -1 : 1;
          var force =
            (sign * Math.sqrt(alpha) * nl.factor * Math.max(0, nl.yr - ady)) / Math.max(nl.xMin, adx);
          n.y -= force;
          if (!m.fixed) m.y += force;
        }
      }
    }
    if (allFixed) {
      this.force.stop();
    }
  }

  update() {
    this.nodeRender.update(this.nodeElems);
    this.linkRender.update(this.linkElems);
    this.rescale();
  }

  markerFor(color, forward) {
    var id = (forward ? 'f' : 'b') + (color + '').replace(/[^a-z0-9A-Z]/g, '-');
    if (!this.markers[id]) {
      var marker = this.svgDefs
        .append('svg:marker')
        .attr('id', id)
        .attr('viewBox', '0 -5 10 10')
        .attr('markerWidth', 8)
        .attr('markerHeight', 8)
        .attr('orient', 'auto')
        .attr('fill', color)
        .attr('markerUnits', 'userSpaceOnUse');
      if (forward) {
        marker.attr('refX', '6').append('svg:path').attr('d', 'M0,-5L10,0L0,5z');
      } else {
        marker.attr('refX', '4').append('svg:path').attr('d', 'M10,-5L0,0L10,5z');
      }
      this.markers[id] = true;
    }
    return 'url(#' + id + ')';
  }

  scaleMarkers() {
    var w = this.scaleInv * 8;
    for (var id in this.markers) {
      d3.select('#' + id)
        .attr('markerWidth', w)
        .attr('markerHeight', w);
    }
  }

  fitZoom() {
    var minx = 1e50,
      miny = 1e50,
      maxx = -1e50,
      maxy = -1e50;
    for (var i in this.nodes) {
      var n = this.nodes[i];
      if (n.xd < minx) minx = n.xd;
      if (n.xd > maxx) maxx = n.xd;
      if (n.yd < miny) miny = n.yd;
      if (n.yd > maxy) maxy = n.yd;
    }
    var bbox = this.bgRect.node().getBBox();
    var scale =
      Math.min(5, Math.min((bbox.width - 80) / (maxx - minx), (bbox.height - 40) / (maxy - miny))) / 1.02;

    var vx = (maxx + minx) / 2,
      vy = (maxy + miny) / 2,
      tx = -scale * vx + bbox.width / 2,
      ty = -scale * vy + bbox.height / 2;
    this.scale2(scale, tx, ty);
  }

  canSelect(event = d3.event) {
    return this.selectionMode || (event && (event.shiftKey || event.ctrlKey));
  }

  onBgDblClick() {
    if (this.canSelect()) {
      this.setZoom(false);
      if (this.nodeSelection.length == 0) {
        this.setNodeSelection(this.nodeElems);
      } else {
        this.clearNodeSelection();
      }
      this.setZoom(true);
      this.event.selectionChanged({ type: 'selectionChanged' });
    }
  }

  onBgMouseDown(self) {
    if (self.canSelect()) {
      self.setZoom(false);
      self.setSelectionBox(this, self);
    }
  }

  setSelectionBox(target, self) {
    var p0 = d3.mouse(target.parentNode),
      pc = d3.mouse(self.canvas.node());

    var w = d3.select(window).on('mousemove.graph', selMove).on('mouseup.graph', selEnd, true);
    window.focus();
    d3.event.stopPropagation();
    d3.event.preventDefault();

    function selMove() {
      var p = d3.mouse(target.parentNode),
        dx = p[0] - p0[0],
        dy = p[1] - p0[1];

      self.selRect
        .attr('x', Math.min(p0[0], p[0]))
        .attr('y', Math.min(p0[1], p[1]))
        .attr('width', Math.abs(dx))
        .attr('height', Math.abs(dy))
        .style('display', 'inline');

      d3.event.stopPropagation();
      d3.event.preventDefault();
    }

    function selEnd() {
      w.on('mousemove.graph', null).on('mouseup.graph', null);
      self.selRect.style('display', 'none');
      self.setZoom(true);
      var p = d3.mouse(self.canvas.node()),
        x0 = Math.min(pc[0], p[0]), // - graph.cx,
        y0 = Math.min(pc[1], p[1]), // - graph.cy,
        x1 = Math.max(pc[0], p[0]), // - graph.cx,
        y1 = Math.max(pc[1], p[1]); // - graph.cy;
      var toSelect = self.nodeElems.filter(function(d) {
        return d.xd >= x0 && d.yd >= y0 && d.xd <= x1 && d.yd <= y1;
      });
      //не изменять выделение, если рамкой не выделено ни одного узла
      if (toSelect.node() == null) {
        return;
      }

      const ids = [];

      toSelect.each((node) => {
        ids.push(node.ID);
      });

      new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.CLICK_NODE, ids);

      // if (d3.event.shiftKey) {
      //   self.clearSelection(toSelect);
      // } else {
      //   self.setSelection(toSelect);
      // }
      //
      // self.notifyComponentSelectedChanged(self.selection.map((item) => item.ID));

      // self.event.selectionChanged({ type: 'selectionChanged' });
    }
  }

  translate(x, y) {
    this.tx = x;
    this.ty = y;
    this.zoom.translate([x, y]);
    this.scalePane.attr('transform', 'translate(' + this.tx + ',' + this.ty + ') scale(' + this.tScale + ')');
  }

  scale(scale) {
    if (!arguments.length) return this.sc;
    if (scale == 0) return;
    scale = parseFloat(scale);

    var bbox = this.bgRect.node().getBBox(),
      //центр экрана
      bx = bbox.width / 2,
      by = bbox.height / 2,
      vx = 0, //graph.cx,
      vy = 0, //graph.cy,
      //центр графа на экране
      cx = this.tx + vx * this.sc,
      cy = this.ty + vy * this.sc,
      //смещение центра графа отностительно центра экрана
      ds = scale / this.sc,
      //новый центр графа
      cx1 = (cx - bx) * ds + bx,
      cy1 = (cy - by) * ds + by,
      tx = cx1 - vx * scale,
      ty = cy1 - vy * scale;

    this.scale2(scale, tx, ty);

    this.currentScale = scale;
  }

  isSkeleton() {
    return this.sc <= this.skeletonScale;
  }

  rescale() {
    this.scalePane
      .attr('transform', 'translate(' + this.tx + ',' + this.ty + ') scale(' + this.sc + ')')
      .classed('skeleton', this.isSkeleton());

    if (this.isSkeleton()) {
    }

    this.nodeElems.each((d) => {
      const view = d.view.querySelector('.ak_label');

      if (this.isSkeleton()) {
        view.style.display = 'none';
      } else {
        view.style.display = 'block';
      }
    });

    if (this.sc !== this.scale0) {
      this.scale0 = this.sc;
      this.scaleInv = 1 / this.sc;
      this.redraw();
    }
  }

  setZoom(enable) {
    if (this.selectionMode) enable = false;
    if ((enable && this.zoomEnabled) || (!enable && !this.zoomEnabled)) return;
    var svg = d3.select(this.bgRect.node().parentNode);
    if (enable) {
      svg.call(this.zoom);
      svg.on('dblclick.zoom', null);
    } else {
      svg.on('mousedown.zoom', null);
      svg.on('touchstart.zoom', null);
      d3.select(window).on('.zoom', null);
      if (d3.event && d3.event.stopImmediatePropagation) d3.event.stopImmediatePropagation();
    }
    this.zoomEnabled = enable;
  }

  transformEvent() {
    var pt = d3.event.translate;
    this.scale2(d3.event.scale, pt[0], pt[1], true);
  }

  scale2(scale, tx, ty, skipZoomer) {
    var fireScaleChanged = Math.abs(scale - this.sc) > 1e-6;
    this.sc = scale;
    this.tx = tx;
    this.ty = ty;
    if (!skipZoomer) {
      this.zoom.scale(scale);
      this.zoom.translate([tx, ty]);
    }
    this.rescale();
    if (fireScaleChanged) this.event.scaleChanged({ scale: scale });
  }

  scaleCenter(scale) {
    var bbox = this.bgRect.node().getBBox(),
      vx = 0, // graph.cx,
      vy = 0, // graph.cy,
      tx = -scale * vx + bbox.width / 2,
      ty = -scale * vy + bbox.height / 2;
    this.scale2(scale, tx, ty);
  }

  notifyComponentSelectedChanged = (nodes) => {
    const event = new CustomEvent('eventSelectedNodesIdsChanges', {
      detail: {
        nodes,
      },
    });

    document.dispatchEvent(event);
  };

  clickNode = (e) => {
    const d = e.d;
    const pointerEvent = e.ev;

    if (d._noClick > 3) return;

    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.CLICK_NODE, [d.ID, this.canSelect(pointerEvent)]);
  };

  onNodeClick(e) {
    var node = d3.select(e.el);

    var d = e.d;

    if (d._noClick > 3) return;

    if (this.canSelect()) {
      if (d.selected) {
        // d.selected = false;
        this.clearNodeSelection(node);
      } else {
        // d.selected = true;
        if (this.singleSelect) this.clearNodeSelection();
        this.setNodeSelection(node);
      }
      this.event.selectionChanged({ type: 'selectionChanged' });

      this.notifyComponentSelectedChanged(this.nodeSelection.map((item) => item.ID));
    } else if (!this.safeSelection) {
      this.clearNodeSelection();
      this.setNodeSelection(node);
      this.event.selectionChanged({ type: 'selectionChanged' });
    }
  }

  doubleClickNode = (e) => {
    const d = e.d;

    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.DOUBLE_CLICK_NODE, [d.ID]);
  };

  clickLink = (e) => {
    const d = e.d;

    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.CLICK_LINK, [d.ID]);
  };

  doubleClickLink = (e) => {
    const d = e.d;

    new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.DOUBLE_CLICK_LINK, [d.ID]);
  };

  setNodeSelection(nodes) {
    nodes.each((d) => {
      const domRef = d.view;

      const circle = domRef.querySelector('.ak_node_selection');

      circle.style.stroke = this.options.selectedNodeColor;

      if (!d.selected) {
        this.nodeSelection.push(d);
        this.setSelected(d, true);
      }
    });

    this.nodeRender.updateSelection(nodes);
  }

  clearNodeSelection(nodes) {
    if (!nodes) {
      //полная очистка выделения

      var n = this.nodeSelection.length;
      for (var i = 0; i < n; i++) {
        const ref = this.nodeSelection[i].view;

        const circle = ref.querySelector('.ak_node_selection');

        if (circle) {
          circle.style.stroke = '';
        }

        this.setSelected(this.nodeSelection[i], false);
      }
      this.nodeSelection = [];
      this.nodeRender.updateSelection(this.nodeElems);
      return;
    }

    nodes.each(
      function(d) {
        const ref = d.view;

        const circle = ref.querySelector('.ak_node_selection');

        if (circle) {
          circle.style.stroke = '';
        }

        this.setSelected(d, false);
      }.bind(this),
    );
    this.nodeRender.updateSelection(nodes);
  }

  setLinkSelection(links) {
    links.each(
      function (d) {
        const domRef = d.view;

        const line = domRef.querySelector('.ak_link_path');

        line.style.stroke = this.options.selectedLinkColor;

        if (!d.selected) {
          this.linkSelection = d;
        }
      }.bind(this),
    );

    this.linkRender.update(links);
  }

  clearLinkSelection(links) {
    links.each(
      function (d) {
        const ref = d.view;

        const line = ref.querySelector('.ak_link_path');

        if (line) {
          line.style.stroke = '';
        }

        this.setSelected(d, false);
      }.bind(this),
    );

    this.linkRender.update(links);
  }

  setSelected(d, value) {
    d.selected = value;
    //зафиксировать выбранные узлы
    if (value && this.fixedSelection) d.fixed |= 32;
    else d.fixed &= ~32;
  }

  setFixed(d, fixed) {
    if (fixed) {
      d.fixed |= 16;
    } else {
      d.fixed &= ~16;
    }
    this.nodeRender.updateFixed(d3.select(d.view), fixed);
  }

  isFixed(d) {
    return (d.fixed & 16) != 0;
  }

  initDrag() {
    const self = this;

    this.drag
      .on('dragstart.graph', this.onDragStart.bind(this))
      .on('drag.graph', this.onMultiDrag.bind(this))
      .on('dragend.graph', function(d) {
        self.event.dragEnd({ type: 'dragEnd', node: d });
        //костыль для отключения события click при перетаскивании
        setTimeout(function() {
          delete d._noClick;
        }, 350);
        //if (graph.fixAllOnDrag) for (var i in nodes) nodes[i].fixed &= ~32;
      });
  }

  onMultiDrag(d) {
    d._noClick = (d._noClick || 0) + 1;
    //debugger;
    var dx = d3.event.x - this.dragInfo.x0;
    var dy = d3.event.y - this.dragInfo.y0;
    //d.px = d3.event.x;
    //d.y = d3.event.y;
    for (var i = 0; i < this.dragInfo.nodes.length; i++) {
      var o = this.dragInfo.nodes[i];
      var x = (o.d.xd = o.x0 + dx);
      var y = (o.d.yd = o.y0 + dy);
      var al = this.getAl(x, y);
      o.d.x = o.d.px = x / al.x;
      o.d.y = o.d.py = y / al.y;
    }

    this.nodeRender.position(this.dragInfo.view);
    //graph.drawLinks(linkElems);
  }

  onDragStart(d) {
    if (d3.event.sourceEvent && d3.event.sourceEvent.stopPropagation) d3.event.sourceEvent.stopPropagation();
    else if (d3.event.stopPropagation) d3.event.stopPropagation();
    this.dragInfo = {
      x0: d.xd,
      y0: d.yd,
      nodes: [],
    };
    //прекратить движение узлов при перетаскивании
    if (this.fixAllOnDrag) for (var i in this.nodes) this.setFixed(this.nodes[i], true); //nodes[i].fixed |= 32;

    this.dragInfo.nodes.push({ d: d, x0: d.xd, y0: d.yd });
    this.dragInfo.view = d3.select(this);
    this.setFixed(d, true);

    var views = [];
    for (var i = 0, n = this.nodeSelection.length; i < n; i++) {
      var o = this.nodeSelection[i];
      this.dragInfo.nodes.push({ d: o, x0: o.xd, y0: o.yd });
      this.setFixed(o, true);
      views[i] = o.view;
    }
    this.dragInfo.view = d3.selectAll(views);
  }
}
