import { NodeRawFields, NodeElementType, Fio } from '@core/models/relation-map-types';
import { IdGenerator } from '@core/utils/id-generator';
import { useForm } from '@hooks/useForm';

export type EditNodeForm = {
  type: NodeElementType | null;
  fio: Fio;
  name: string;
  linkLevel: number;
  isClusterNode: boolean;
  isClient: boolean;
  isJudge: boolean;
};

const initForm = (
  node: NodeRawFields | null,
): EditNodeForm => {
  const defaultNode = {
    type: null,
    fio: {
      lastName: '',
      firstName: '',
      middleName: '',
      current: true
    },
    name: '',
    linkLevel: 0,
    isClusterNode: false,
    isClient: false,
    isJudge: false,
  };

  if (node) {
    return {
      type: node.type || defaultNode.type,
      fio: node.fio ? (node.fio.find(item => item.current === true) || node.fio[0]) : defaultNode.fio,
      name: node.name || defaultNode.name,
      linkLevel: node.linkLevel || defaultNode.linkLevel,
      isClusterNode: node.isClusterNode || defaultNode.isClusterNode,
      isClient: node.isClient || defaultNode.isClient,
      isJudge: node.isJudge || defaultNode.isJudge,
    }
  }

  return defaultNode;
};

export const useNodeEditForm = (
  onSave: (value: NodeRawFields) => void,
  nodeData: NodeRawFields | null,
) => {

  const {
    value,
    onChange,
    onValidate,
    validation,
    formTouched,
    setFormTouched,
    setInitialValue,
  } = useForm<EditNodeForm, EditNodeForm>(initForm(nodeData), {
    type: (form: EditNodeForm) => form.type !== null,
    fio: () => true,
    name: (form: EditNodeForm) => form.type === 'NodeOrganizationType'
      ? form.name.trim().length > 0
      : true,
    linkLevel: (form: EditNodeForm) => form.linkLevel >= 0 && form.linkLevel <= 5,
    isClusterNode: () => true,
    isClient: () => true,
    isJudge: () => true,
  });

  const onChangeForm = (key: keyof EditNodeForm, value: any) => {
    onChange(key, value);
  };

  const onSaveForm = async () => {
    const isValidObject = onValidate();

    if (!isValidObject) {
      return;
    }

    const {
      type,
      fio,
      name,
      linkLevel,
      isClusterNode,
      isClient,
      isJudge
    } = value;

    if (!type) {
      return;
    }

    let fioList: Fio[] = [];
    if (nodeData && nodeData.fio) {
      fioList = [...nodeData.fio]
      const currentFio = fioList.find(item => item.current === true);
      if (currentFio) {
        const index = fioList.indexOf(currentFio);
        fioList[index] = fio;
      } else {
        fioList.push(fio);
      }
    } else if (type === 'NodePersonType') {
      fioList.push(fio);
    }

    const nodeFields: NodeRawFields = {
      ...(nodeData || { id: IdGenerator.getId() }),
      type: type,
      linkLevel: linkLevel,
      fio: type === 'NodePersonType' ? fioList : undefined,
      name: type === 'NodeOrganizationType' ? name : undefined,
      isClusterNode: isClusterNode,
      isClient: type === 'NodePersonType' ? isClient : null,
      isJudge: type === 'NodePersonType' ? isJudge : null
    };

    onSave(nodeFields);
    setFormTouched(false);
  };

  return {
    formValue: value,
    validation,
    formTouched,
    onSaveForm,
    onChangeForm,
    onValidate,
  };
};
