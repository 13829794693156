import React, { CSSProperties, useMemo } from 'react';
import { RelationMapLink } from '@core/classes/relation-map-link';

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
};

const SmartTableEdgeName = (props: { data: RelationMapLink }) => {
  const edge = useMemo(() => {
    return (
      <div style={styles}>
        <span>{props.data.id1}</span>
        <span>{props.data.id2}</span>
      </div>
    );
  }, [props.data]);

  return edge;
};

export default SmartTableEdgeName;
