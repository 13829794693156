import { ColDef } from 'ag-grid-community';
import { tableComparator } from '@components/SmartTable/domain/comparator';

export const nodesTableColDefs: ColDef[] = [
  {
    headerName: '',
    colId: 'checkbox',
    field: 'checkbox',
    sortable: false,
    resizable: false,
    minWidth: 40,
    maxWidth: 40,
    hide: false,
    lockPosition: true,
    cellClass: 'cell-centered jcc',
    cellRenderer: params => {
      const input = document.createElement('input');
      input.type = 'checkbox';
      input.checked = !params.data.hidden;
      input.addEventListener('click', event => {
        params.value = !input.checked;
        params.node.data.hidden = params.value;
      });
      return input;
    }
  },
  {
    colId: 'name',
    headerName: 'Наименование',
    field: 'name',
    hide: false,
    editable: false,
    filter: 'smartTableFilter',
    flex: 1,
    cellRenderer: 'smartTableNodeName',
    comparator: tableComparator,
    suppressSizeToFit: true,
  },
];
