import React, { useEffect, useMemo, useRef, useState } from 'react';
import clx from 'classnames';
import SimpleCard from '@components/SimpleCard';
import { ICommonList } from '@core/interfaces/common-list.interface';
import { RelationMapNode } from '@core/classes/relation-map-node';
import { RelationMapLink } from '@core/classes/relation-map-link';
import { DetailCard } from '@core/models/relation-map-types';
import { IRelationMapVisualization } from '@core/models/relation-map-visualization';
import { makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { contextNodeItemsMap, contextEdgeItemsMap } from './list';
import { ContextRelationMapAction } from './list';
import { DocumentEventsGraph, DocumentEventsGraphTypes } from '@pages/RelationMap/events';
import { init, ObjectGraph } from '@vendor/xf/object-graph';
import './styles.scss';

interface IGraphProps {
  graph: ObjectGraph | undefined;
  nodes: RelationMapNode[];
  links: RelationMapLink[];
  selectedNodesIds: string[];
  hiddenNodesIds: string[];
  preventInit: boolean;
  keepPositions: boolean;
  options: IRelationMapVisualization;
  onAction: (action: ContextRelationMapAction, nodeId: string | null, edgeId: string | null) => void;
  onSetGraph: (graphObj: ObjectGraph | undefined) => void;
}

const useStyles = makeStyles({
  graph: {
    width: '100%',
    height: '100%',
  },
  flexArticle:
  {
    flex: '3 3'
  },
  flexAside: {
    overflowX: 'hidden',
    overflowY: 'auto',
    flex: '0',
    zIndex: 1000,
    position: 'fixed',
    right: '10px',
    padding: '0 0 5px 5px',
    transition: 'transform 0.2s ease-in'
  },
  visible: {
    transform: 'translateX(0)',
  },
  hidden: {
    transform: 'translateX(100%)',
  }
});

const initialState = {
  mouseX: null,
  mouseY: null,
};

const Graph = (props: IGraphProps) => {
  const classes = useStyles();
  const { graph, nodes, links, preventInit, keepPositions, options } = props;

  const emptyCard: DetailCard = {
    open: false,
    title: '',
    text: ''
  };

  const graphRef = useRef<HTMLElement>(null);
  const nodesRef = useRef<RelationMapNode[]>([]);
  const linksRef = useRef<RelationMapLink[]>([]);

  const [detailCard, setDetailCard] = useState<DetailCard | null>(emptyCard);
  const [cardHeight, setCardHeight] = useState<number>(0);

  const [position, setPosition] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  const [nodeId, setNodeId] = useState<string | null>(null);

  const [edgeId, setEdgeId] = useState<string | null>(null);

  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    linksRef.current = links;
  }, [links]);

  useEffect(() => {
    setDetailCard(null);
    if (preventInit === false && (nodes.length > 0 || links.length > 0)) {
      if (graph) {
        graph.init(nodes, links, keepPositions);
      } else {
        const graphObj = init(nodes, links);
        props.onSetGraph(graphObj);
      }

      new DocumentEventsGraph().triggerEvent(DocumentEventsGraphTypes.CHANGE_OPTIONS, {
        options: options,
      });
    }
  }, [preventInit, nodes, links, options]);

  useEffect(() => {
    document.addEventListener(DocumentEventsGraphTypes.DOUBLE_CLICK_NODE, doubleClickNodeHandle);
    document.addEventListener(DocumentEventsGraphTypes.DOUBLE_CLICK_LINK, doubleClickEdgeHandle);
  }, []);

  useEffect(() => {
    if (graphRef && graphRef.current) {
      setCardHeight(graphRef.current.offsetHeight);
    }
  }, [graphRef.current?.offsetHeight]);

  const doubleClickNodeHandle = (event: any) => {
    const data = event.detail.data;

    if (data.length === 1) {
      const id = data[0];

      const node = nodesRef.current.find((node) => node.id === id);

      if (node) {
        const card: DetailCard = {
          open: true,
          title: `Узел: ${node.name}`,
          text: node.data
        };

        setDetailCard(card);
      }
    }
  };

  const doubleClickEdgeHandle = (event: any) => {
    const data = event.detail.data;

    if (data.length === 1) {
      const id = data[0];

      const link = linksRef.current.find((link) => link.id === id);

      if (link) {
        const node1Name = nodesRef.current.find((node) => node.id === link.id1)?.name;
        const node2Name = nodesRef.current.find((node) => node.id === link.id2)?.name;

        const card: DetailCard = {
          open: true,
          title: `Связь: ${node1Name} - ${node2Name}`,
          text: link.data
        };

        setDetailCard(card);
      }
    }
  };

  const handleCardClose = () => {
    setDetailCard({
      ...(detailCard || emptyCard),
      open: false
    });
  };

  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    const nodeId: string | null = (event.target as HTMLElement).getAttribute('nodeId');

    const linkId: string | null = (event.target as HTMLElement).getAttribute('linkId');

    const show = () => {
      setPosition({
        mouseX: event.clientX,
        mouseY: event.clientY,
      });
    };

    if (nodeId) {
      setNodeId(nodeId);
      show();
    }

    if (linkId) {
      setEdgeId(linkId);
      show();
    }
  };

  const handleMenuClose = () => {
    setPosition({ ...initialState });
    setEdgeId(null);
    setNodeId(null);
  };

  const getList = useMemo(() => {
    if (nodeId === null && edgeId === null) {
      return [];
    }

    if (nodeId !== null) {
      return contextNodeItemsMap;
    }

    if (edgeId !== null) {
      return contextEdgeItemsMap;
    }

    return [];
  }, [nodeId, edgeId]);

  const select = (key: ContextRelationMapAction) => {
    props.onAction(key, nodeId, edgeId);
    handleMenuClose();
  };

  return (
    <>
      <article className={classes.flexArticle} ref={graphRef}>
        <div id='graph' className={classes.graph} onContextMenu={onContextMenu} />
      </article>

      {detailCard && (
        <aside className={detailCard.open
          ? clx(classes.flexAside, classes.visible)
          : clx(classes.flexAside, classes.hidden)} style={{ maxHeight: cardHeight }}>
          <SimpleCard
            title={(detailCard || emptyCard).title}
            text={(detailCard || emptyCard).text}
            onClose={handleCardClose}
          />
        </aside>
      )}

      {getList.length > 0 && (
        <Menu
          keepMounted
          open={position.mouseY !== null}
          onClose={handleMenuClose}
          anchorReference='anchorPosition'
          anchorPosition={
            position.mouseY !== null && position.mouseX !== null
              ? { top: position.mouseY, left: position.mouseX }
              : undefined
          }
        >
          {getList.map((item: ICommonList) => {
            return (
              <MenuItem key={item.id} onClick={() => select(item.key)}>
                {item.value}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default Graph;
