import { IconButton, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

interface ISimpleCardProps {
  title: string;
  text: string;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  content: {
    width: '300px',
    height: '100%',
    overflowY: 'auto',
    padding: '10px'
  },
  title: {
    fontFamily: 'inherit',
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.2'
  },
  subtitle: {
    display: 'block',
    margin: '10px 0',
    fontWeight: 600
  },
  text: {
    fontFamily: 'inherit',
    fontWeight: 300,
    wordBreak: 'break-word'
  },
  closeBtn: {
    display: 'block'
  }
});

const SimpleCard = (props: ISimpleCardProps) => {
  const classes = useStyles();
  const { title, text, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} variant="h5" component="h5">
          {title}
        </Typography>
        <span className={classes.subtitle}>
          {'Детальная информация:'}
          <Typography
            className={classes.text}
            variant="body2"
            component="p"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </span>
      </CardContent>
      <div className='closeBtn'>
        <IconButton size='small' onClick={() => handleClose()}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </div>
    </Card>
  );
}

export default SimpleCard;
