import React, { CSSProperties, useCallback, useMemo } from 'react';
import AppTooltip from '@components/AppTooltip';
import { RelationMapNode } from '@core/classes/relation-map-node';
import { ObjectAttributes } from "@core/default-settings/object-attributes";
import { Tooltip } from '@material-ui/core';

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const objects = ObjectAttributes.defaultList;

const SmartTableNodeName = (props: { data: RelationMapNode }) => {
  const getTooltip = useCallback((type: number) => {
    const object = objects.find((o) => o.type === type);

    if (object === undefined) {
      return '';
    }

    return object.value;
  }, []);

  const name = useMemo(() => {
    const icon = ObjectAttributes.getObjectIcon()[props.data.type];

    return (
      <div style={styles}>
        <AppTooltip title={getTooltip(props.data.type)}>
          <div style={{ display: 'flex', marginRight: '10px' }}>{icon}</div>
        </AppTooltip>
        <Tooltip title={props.data.name}>
          <span>{props.data.name}</span>
        </Tooltip>
      </div>
    );
  }, [props.data]);

  return name;
};

export default SmartTableNodeName;
